import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
import Dashboard from './cmp/dashboard';
import Register from './cmp/register';
import Success from './cmp/success';
import Events from './cmp/events';
import GalleryCollections from './cmp/gallery-collections';
import Gallery from './cmp/gallery';

import Contact from './cmp/contact';
import Login from './cmp/admin/login';
import AdminDashboard from './cmp/admin/admin-dashboard';
import AdminOrders from './cmp/admin/admin-orders';
import AdminSettings from './cmp/admin/admin-settings';
import AdminEvents from './cmp/admin/admin-events';
import AdminAddEvent from './cmp/admin/admin-add-event';
import AdminAddVendor from './cmp/admin/admin-add-vendor';
import AdminGallery from './cmp/admin/admin-gallery';
import AdminGalleryPhotos from './cmp/admin/admin-gallery-photos';
import AdminVendors from './cmp/admin/admin-vendors';
import Vendors from './cmp/vendors';
import VendorDetails from './cmp/vendor-details';

import { ToastContainer, toast } from 'react-toastify';

const HomeContainer = () => {

  return (
    <div style={{ display: 'flex', flexDirection: 'row', position:'fixed', height:'100%', width:'100%'}}>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1,  }}>
        <div style={{ display: 'flex' }}>
          <Route path='/' exact component={Dashboard} />
          <Route path='/register' exact component={Register} />
          <Route path='/success' exact component={Success} />
          <Route path='/contact' exact component={Contact} />
          <Route path='/events' exact component={Events} />
          <Route path='/gallery-collections' exact component={GalleryCollections} />
          <Route path='/gallery' exact component={Gallery} />
          <Route path='/login' exact component={Login} />
          <Route path='/admin-dashboard' exact component={AdminDashboard} />
          <Route path='/admin-orders' exact component={AdminOrders} />
          <Route path='/admin-settings' exact component={AdminSettings} />
          <Route path='/admin-events' exact component={AdminEvents} />
          <Route path='/admin-add-event' exact component={AdminAddEvent} />
          <Route path='/admin-gallery' exact component={AdminGallery} />
          <Route path='/admin-gallery-photos' exact component={AdminGalleryPhotos} />
          <Route path='/admin-vendors' exact component={AdminVendors} />
          <Route path='/vendors' exact component={Vendors} />
          <Route path='/vendor-details' exact component={VendorDetails} />
          <Route path='/admin-add-vendor' exact component={AdminAddVendor} />
        </div>
      </div>
    </div>
  );
}

class App extends React.Component {
  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route component={HomeContainer} />
          </Switch>
          <ToastContainer />
        </div>
      </Router>
    );
  }
}

export default App;
