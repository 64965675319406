
const MyError = ({ showError, error }) => {
    console.log('showError: ' + showError);
    if (showError === false)
        return null
    return <div
        style={{ width: '100%', marginTop: 20, alignSelf: 'center', color: 'red', fontWeight: 'bold', fontSize: 15, textAlign: 'center' }}>
        {error}
    </div>
};

export default MyError