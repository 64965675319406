import React, { useState, useEffect, useRef } from 'react';
import '../../App.css';
import { useLocation, useHistory } from 'react-router-dom';
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import MyStorage from '.././util/storage'
import background from '../../assets/bg.png';
import Loader from 'react-loader-spinner'
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';
import home from '../../assets/home2.png';
import { ToastContainer } from 'react-toastify';
import showToast from '.././util/toast'
import db, { storage } from '.././firebase/firebase';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const AdminAddEvent = () => {
  const history = useHistory()
  const location = useLocation()

  const model = location.state.model
  const editMode = model != null

  const fromHTML = (html) => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    return editorState
  }

  const [title, setTitle] = useState(editMode ? model.title : '');
  const [editorState, setEditorState] = useState(editMode ? fromHTML(model.detail) : EditorState.createEmpty());
  const [date, setDate] = useState(editMode ? new Date(moment(model.date, 'MM-DD-YYYY h:m a')) : new Date());
  const [mainShot, setMainShot] = useState(null);
  const [mainShotUrl, setMainShotUrl] = useState(editMode ? model.image : null);
  const [mainShotName, setMainShotName] = useState(null);
  const [loader, setLoader] = useState(false);
  const addMainShot = useRef(null);

  if (!MyStorage.isLogin()) {
    console.log('not logged in');
    history.replace('/login')
    return null
  }

  const addNewEvent = () => {
    if (title === '' || mainShot == null) {
      showToast('error', 'Fill complete form!')
      return
    }

    let detail = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    console.log('detail: ' + detail);
    if (detail.length < 10) {
      showToast('error', 'Please enter body!')
      return
    }

    setLoader(true)
    console.log('mainShotName: ' + mainShotName);
    const uploadTask = storage.ref('events/' + mainShotName).put(mainShot);
    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        console.log('progress: ' + progress);
      },
      (e) => {
        console.log(e);
      },
      () => {
        // success
        storage.ref().child('events/' + mainShotName).getDownloadURL().then((url) => {
          console.log('url: ' + url);
          let event = {
            image: url,
            title: title,
            detail: detail,
            date: moment(date).format('MM-DD-YYYY h:m a')
          }
          db.collection('events')
            .add(event)
            .then(() => {
              setLoader(false)
              history.goBack()
            })
            .catch(e => {
              setLoader(false)
            });
        })
      });
  };

  const editNewModel = () => {
    if (title === '') {
      showToast('error', 'Fill complete form!')
      return
    }

    let detail = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    console.log('detail: ' + detail);
    if (detail.length < 10) {
      showToast('error', 'Please enter body!')
      return
    }

    if (mainShot == null) {
      // no photo update
      setLoader(true)
      model.title = title
      model.detail = detail
      model.date = moment(date).format('MM-DD-YYYY h:m a')
      db.collection('events')
        .doc(model.id)
        .set(model, { merge: true })
        .then(() => {
          setLoader(false)
          history.goBack()
        })
        .catch(e => {
          setLoader(false)
        });
    }
    else {
      setLoader(true)
      console.log('mainShotName: ' + mainShotName);
      const uploadTask = storage.ref('events/' + mainShotName).put(mainShot);
      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          console.log('progress: ' + progress);
        },
        (e) => {
          console.log(e);
        },
        () => {
          // success
          storage.ref().child('events/' + mainShotName).getDownloadURL().then((url) => {
            console.log('url: ' + url);
            model.image = url
            model.title = title
            model.detail = detail
            model.date = moment(date).format('MM-DD-YYYY h:m a')
            db.collection('events')
              .doc(model.id)
              .set(model, { merge: true })
              .then(() => {
                setLoader(false)
                history.goBack()
              })
              .catch(e => {
                setLoader(false)
              });
          })
        });
    }
  };

  const onEditorStateChange = (editorState) => {
    console.log(JSON.stringify(editorState));
    setEditorState(editorState)
  };

  return (
    <div style={{ marginTop: 0, marginLeft: 0, marginRight: 0, padding: 0 }}>
      <img
        src={background}
        style={{ position: 'absolute', width: '100%', height: '100vh', objectFit: 'cover' }} />
      <div
        style={{ position: 'absolute', width: '100%', display: 'flex', flexDirection: 'column', padding: 0 }}>
        <div style={{ paddingTop: 30, paddingBottom: 100, display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh', overflow: 'scroll' }}>
          <h3 style={{ color: 'white', alignSelf: 'center' }}>Add Event</h3>
          <Form className='form' style={{ padding: 20, backgroundColor: 'white', borderRadius: 10, width: 450 }}>

            <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
              <img
                src={mainShotUrl}
                style={{ width: 200, height: 150, marginBottom: 10, marginTop: 20, objectFit: 'contain', backgroundColor: '#f2f2f2' }} />
              <Button
                style={{ marginTop: 0, fontSize: 16 }}
                className='btn-block'
                onClick={() => {
                  addMainShot.current.click();
                }}>
                Choose Image
              </Button>
              <FormGroup style={{ marginTop: 20, width: '100%' }}>
                <Input
                  style={{ fontSize: 18 }}
                  type='text'
                  placeholder='Title'
                  value={title}
                  onChange={(event) => {
                    console.log('change: ' + event.target.value);
                    setTitle(event.target.value)
                  }} />
              </FormGroup>
              <div style={{ height: 10 }}></div>
              <Editor
                editorStyle={{ backgroundColor: 'white', marginTop: 0, paddingLeft: 10, paddingRight: 10, border: '1px solid #f2f2f2' }}
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange} />

              {/* <FormGroup style={{ marginTop: 10, width: '100%' }}>
                <Input
                  style={{ fontSize: 18 }}
                  type='textarea'
                  placeholder='Detail'
                  value={detail}
                  onChange={(event) => {
                    console.log('change: ' + event.target.value);
                    setDetail(event.target.value)
                  }} />
              </FormGroup> */}

              <h6 style={{ display: 'flex', alignSelf: 'center', marginTop: 10 }}>
                Choose Date & Time
              </h6>
              <div style={{ alignSelf: 'center', marginTop: 0, backgroundColor: 'white', borderRadius: 5, height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <DateTimePicker
                  format='MM/dd/yyyy h:m a'
                  onChange={(date) => {
                    console.log('date: ' + moment(date).format('MM-DD-YYYY h:m a'));
                    setDate(date)
                  }}
                  value={date} />
              </div>
            </div>

            <input
              type='file'
              style={{ display: 'none' }}
              ref={addMainShot}
              onChange={(event) => {
                setMainShot(event.target.files[0]);
                setMainShotUrl(URL.createObjectURL(event.target.files[0]))
                setMainShotName(event.target.files[0].name)
              }} />

            <Button
              className='btn-block btn-dark'
              style={{ marginTop: 15, fontSize: 18, fontWeight: 'bold', width: '100%', backgroundColor: 'orange', borderWidth: 0, height: 50 }}
              onClick={() => {
                if (!editMode)
                  addNewEvent()
                else
                  editNewModel()
              }}>
              Save Event
            </Button>
          </Form>
        </div>
      </div>

      {loader ?
        <div style={{ position: 'absolute', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          <div style={{ padding: 10, backgroundColor: 'red', borderRadius: 100 }}>
            <Loader
              type="ThreeDots"
              color="white"
              height={70}
              width={70}
              visible={true} />
          </div>
        </div>
        :
        null
      }

      <div>
        <ToastContainer />
      </div>

      <button
        onClick={() => {
          window.location.replace('/admin-dashboard')
        }}
        className='home-btn'>
        <img
          src={home}
          className='home-btn-img' />
      </button>

    </div>
  );
}

export default AdminAddEvent;