import React, { useState, useEffect, useRef } from 'react';
import '../../App.css';
import { Link, useHistory } from 'react-router-dom';
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import MyStorage from '.././util/storage'
import background from '../../assets/bg.png';
import home from '../../assets/home2.png';
import showToast from '.././util/toast'
import Loader from 'react-loader-spinner'
import { ToastContainer } from 'react-toastify';
import db, { storage } from '.././firebase/firebase';
import Api from '../api/api'
import moment from 'moment';

const AdminGallery = () => {
    const history = useHistory()
    const [models, setModels] = useState([])
    const [events, setEvents] = useState([])
    const [eventTitle, setEventTitle] = useState('')
    const [loader, setLoader] = useState(false)

    const [mainShot, setMainShot] = useState(null);
    const [mainShotUrl, setMainShotUrl] = useState(null);
    const [mainShotName, setMainShotName] = useState(null);
    const addMainShot = useRef(null);

    useEffect(() => {
        loadData()
    }, [])

    if (!MyStorage.isLogin()) {
        console.log('not logged in');
        history.replace('/login')
        return null
    }

    const groupItems = (items) => {
        const memo = items.reduce((memo, object) => {
            if (object.eventTitle in memo)
                memo[object.eventTitle].push(object);
            else
                memo[object.eventTitle] = [object];
            return memo;
        }, {});
        let models = [];
        Object.keys(memo)
            .reduce((a, key) => {
                const objects = memo[key]
                    .sort()
                // console.log(key);
                // console.log(JSON.stringify(objects));
                let model = {
                    key: key,
                    data: objects
                }
                models = models.concat(model);
            }, [])
        return models;
    }

    const loadData = () => {
        setLoader(true)
        db.collection("gallery")
            .get()
            .then(querySnapshot => {
                setLoader(false)
                let temp = []
                let temp1 = []
                for (let i = 0; i < querySnapshot.docs.length; i++) {
                    const doc = querySnapshot.docs[i];
                    let item = doc.data()
                    temp.push(item)
                    if (item.eventTitle) {
                        temp1.push(item)
                    }
                }
                setModels(temp)
                // group items
                let groups = groupItems(temp1)
                console.log('GROUPS: ' + JSON.stringify(groups));
                console.log('groups: ' + groups.length);
                setEvents(groups)
            })
            .catch(e => {
                setLoader(false)
                showToast('error', 'Load failed!')
            });
    };

    const uploadPhoto = (files, mainShot, mainShotName) => {
        if (mainShot == null) {
            showToast('error', 'Choose Photo!')
            return
        }
        if (eventTitle === '') {
            showToast('error', 'Type Event to Upload Photo!')
            return
        }

        setLoader(true)
        console.log('mainShotName: ' + mainShotName);
        const uploadTask = storage.ref('gallery/' + mainShotName).put(mainShot);
        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                console.log('progress: ' + progress);
            },
            (e) => {
                console.log(e);
            },
            () => {
                // success
                storage.ref().child('gallery/' + mainShotName).getDownloadURL().then((url) => {
                    console.log('url: ' + url);
                    let photo = {
                        image: url,
                        date: '' + moment(),
                        eventTitle: eventTitle.toLowerCase()
                    }
                    db.collection('gallery')
                        .add(photo)
                        .then(() => {
                            setLoader(false)
                            showToast('success', (files.length-1)+' Photos Left')
                            let filesNew = []
                            if (files.length > 1) {
                                for (let i = 1; i < files.length; i++) {
                                    filesNew[i-1] = files[i]
                                }
                            }
                            console.log('filesNew: ' + filesNew.length);
                            if (filesNew.length > 0) {
                                uploadPhoto(filesNew, filesNew[0], filesNew[0].name)
                            }
                            else {
                                showToast('success', 'All Photos Uploaded!')
                                loadData()
                            }
                        })
                        .catch(e => {
                            setLoader(false)
                            showToast('error', 'Add Failed!')
                        })
                })
            })
    }

    const deletePhoto = (photo) => {
        if (window.confirm('Are you sure you want to delete this photo?')) {
            db.collection("gallery")
                .where('image', '==', photo.image)
                .get()
                .then(querySnapshot => {
                    if (querySnapshot.docs.length > 0) {
                        let ID = querySnapshot.docs[0].id
                        db.collection("gallery").doc(ID).delete().then(() => {
                            const temp = models.filter((item, j) => {
                                return item.image !== photo.image; //false will delete
                            });
                            setModels(temp)
                        })
                    }
                    else {
                        showToast('error', 'Delete failed!')
                    }
                })
                .catch(e => {
                    showToast('error', 'Delete failed!')
                });
        }
    };

    return (
        <div style={{ marginTop: 0, marginLeft: 0, marginRight: 0, padding: 0 }}>
            <img
                src={background}
                style={{ position: 'absolute', width: '100%', height: '100vh', objectFit: 'cover' }} />
            <div
                style={{ position: 'absolute', width: '100%', display: 'flex', flexDirection: 'column', padding: 0 }}>
                <div style={{ padding: 30, display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'scroll' }}>
                    <h3 style={{ color: 'white', alignSelf: 'center' }}>Gallery</h3>
                    {/* <input
                        type='text'
                        style={{ width: 300, alignSelf: 'center' }}
                        onChange={(event) => {
                            console.log(event.target.value);
                            setEventTitle(event.target.value)
                        }}
                        value={eventTitle}/> */}
                    <Input
                        style={{ width: 300, textAlign: 'center', alignSelf: 'center' }}
                        type='text'
                        placeholder='Type Event Title'
                        value={eventTitle}
                        onChange={(event) => {
                            console.log('change: ' + event.target.value);
                            setEventTitle(event.target.value)
                        }} />
                    <Button
                        className='btn-block btn-dark'
                        style={{ marginTop: 10, fontSize: 18, fontWeight: 'bold', width: '100%', backgroundColor: 'green', borderWidth: 0, height: 40, width: 300, alignSelf: 'center' }}
                        onClick={() => {
                            addMainShot.current.click();
                        }}>
                        Add Photo
                    </Button>

                    <input
                        type='file'
                        multiple
                        style={{ display: 'none' }}
                        ref={addMainShot}
                        onChange={(event) => {
                            console.log('files: ' + event.target.files.length);
                            uploadPhoto(event.target.files, event.target.files[0], event.target.files[0].name)
                        }} />

                    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                        {events.map((event, index) => {
                            // console.log('image: ' + item.image);
                            return (
                                <div
                                    onClick={() => {
                                        history.push({
                                            pathname: '/admin-gallery-photos',
                                            state: { event: event }
                                        })
                                    }}
                                    style={{ width: 200, backgroundColor: 'white', marginTop: 10, padding: 0, marginRight: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', borderRadius: 10, overflow: 'hidden' }}>
                                    <img src={event.data[0].image} style={{ width: 180, height: 180, objectFit: 'cover' }} />
                                    <div style={{ width: '100%', paddingTop: 8, backgroundColor: '#f2f2f2', textAlign: 'center', fontSize: 16, fontWeight: 'bold' }}>{event.key}</div>
                                    <div style={{ width: '100%', paddingBottom: 8, backgroundColor: '#f2f2f2', textAlign: 'center', fontSize: 14 }}>{event.data.length} Photo(s)</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            {loader ?
                <div style={{ position: 'absolute', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <div style={{ padding: 10, backgroundColor: 'red', borderRadius: 100 }}>
                        <Loader
                            type="ThreeDots"
                            color="white"
                            height={70}
                            width={70}
                            visible={true} />
                    </div>
                </div>
                :
                null
            }

            <button
                onClick={() => {
                    window.location.replace('/admin-dashboard')
                }}
                className='home-btn'>
                <img
                    src={home}
                    className='home-btn-img' />
            </button>
        </div>
    );
}

export default AdminGallery;