import db, { storage, auth } from '../firebase/firebase';
import firebase from "firebase/compat/app";
import moment from 'moment';
import { encode } from "base-64";

const Api = {
    randomLogin: () => {
        auth.signInAnonymously()
            .then(() => {
                console.log('signedInAnonymously');
            })
            .catch((error) => {
                //
            });
    },

    getVendors: (success, error) => {
        db.collection("vendors")
            .get()
            .then(querySnapshot => {
                let models = []
                for (let i = 0; i < querySnapshot.docs.length; i++) {
                    const doc = querySnapshot.docs[i];
                    let id = doc.id
                    let model = doc.data()
                    model.id = id
                    models.push(model)
                }
                // const events = querySnapshot.docs.map(doc => doc.data());
                success(models)
            })
            .catch(e => {
                error('Load failed!')
            });
    },

    getEvents: (success, error) => {
        db.collection("events")
            .get()
            .then(querySnapshot => {
                let models = []
                for (let i = 0; i < querySnapshot.docs.length; i++) {
                    const doc = querySnapshot.docs[i];
                    let id = doc.id
                    let model = doc.data()
                    model.id = id
                    models.push(model)
                }
                // const events = querySnapshot.docs.map(doc => doc.data());
                success(models)
            })
            .catch(e => {
                error('Load failed!')
            });
    },

    deleteOrder: (order, success, error) => {
        db.collection("orders")
            .where('orderId', '==', order.orderId)
            .get()
            .then(querySnapshot => {
                if (querySnapshot.docs.length > 0) {
                    let ID = querySnapshot.docs[0].id
                    db.collection("orders").doc(ID).delete().then(() => {
                        success()
                    })
                }
                else {
                    error('not found!')
                }
            })
            .catch(e => {
                error('Delete failed!')
            });
    },

    deleteVendor: (vendor, success, error) => {
        db.collection("vendors")
            .doc(vendor.id)
            .delete()
            .then(() => {
                success()
            })
    },

    deleteEvent: (event, success, error) => {
        db.collection("events")
            .where('title', '==', event.title)
            .get()
            .then(querySnapshot => {
                if (querySnapshot.docs.length > 0) {
                    let eventID = querySnapshot.docs[0].id
                    db.collection("events").doc(eventID).delete().then(() => {
                        success()
                    })
                }
                else {
                    error('not found!')
                }
            })
            .catch(e => {
                error('Delete failed!')
            });
    },

    getSettings: (success, error) => {
        db.collection("settings")
            .doc('settings')
            .get()
            .then(doc => {
                // console.log(doc.data());
                success(doc.data())
            })
            .catch(e => {
                error('Load failed!')
            });
    },

    saveSettings: (settings, success, error) => {
        db.collection("settings")
            .doc('settings')
            .set(settings, { merge: true })
            .then(() => {
                success()
            })
            .catch(e => {
                error('Load failed!')
            });
    },

    placeOrder: (order, success, error) => {
        order.updated_on = firebase.firestore.FieldValue.serverTimestamp()
        db.collection('orders')
            .add(order)
            .then(() => {
                success()
            })
            .catch(e => {
                error('Order failed!')
            });
    },

    getCredentials: (success, error) => {
        db.collection('credentials')
            .get()
            .then((results) => {
                let credentials = {}
                results.forEach(doc => {
                    credentials[doc.id] = doc.data()
                });
                success(credentials)
            })
            .catch(e => {
                error(e.message)
            });
    },

    stripeToken: async function (key, number, exp_month, exp_year, cvc, onSuccess, onError) {
        let url = 'https://api.stripe.com/v1/tokens'
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + key
        }
        let params = {
            'card[number]': number,
            'card[exp_month]': exp_month,
            'card[exp_year]': exp_year,
            'card[cvc]': cvc
        }
        var formBody = [];
        for (var property in params) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(params[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        console.log('formBody: ' + formBody);
        // remove all white spaces
        formBody = formBody.replace(/%20/g, "");
        apiCall(url, formBody, 'POST', headers, onSuccess, onError)
    },

    stripePayment: async function (key, tokenId, amount, onSuccess, onError) {
        let url = 'https://api.stripe.com/v1/charges'
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + key
        }
        let params = {
            'source': tokenId,
            'amount': amount * 100,
            'currency': 'USD',
        }
        var formBody = [];
        for (var property in params) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(params[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        console.log('formBody: ' + formBody);
        apiCall(url, formBody, 'POST', headers, onSuccess, onError)
    },

    payViaPayPal: (username, password, state, onSuccess, onError) => {
        let url = 'https://api.paypal.com/v1/payments/payment'
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + encode(username + ':' + password)
        }
        let params = {
            "intent": "sale",
            "payer": {
                "payment_method": "paypal"
            },
            "transactions": [
                {
                    "amount": {
                        "total": '' + (state.selModel.price * parseInt(state.quantity)),
                        "currency": "USD"
                    },
                    "description": "Brunch Payment.",
                    "item_list": {
                        "items": [
                            {
                                "name": "Brunch Payment",
                                "quantity": '1',
                                "price": '' + (state.selModel.price * parseInt(state.quantity)),
                                "currency": "USD"
                            }
                        ],
                    }
                }
            ],
            "redirect_urls": {
                "return_url": "https://caribrunch.com/register",
                "cancel_url": "https://caribrunch.com/register"
            }
        }

        console.log('params: ' + JSON.stringify(params));

        apiCall(url, JSON.stringify(params), 'POST', headers, onSuccess, onError)
    },

    adminLogin: (email, password, success, error) => {
        auth.signInAnonymously()
            .then(() => {
                console.log('signedInAnonymously');
                db.collection("admin")
                    .where("email", "==", email)
                    .where("password", "==", password)
                    .get()
                    .then(querySnapshot => {
                        const data = querySnapshot.docs.map(doc => doc.data());
                        if (data.length > 0) {
                            let user = data[0];
                            success(user)
                        }
                        else {
                            error('Could not login')
                        }
                    });
            })
            .catch((error) => {
                error('Could not login')
            });
    },

    updateAdmin: (email, password, success, error) => {
        db.collection('admin')
            .doc('admin')
            .set({
                email: email,
                password: password,
            }, { merge: true })
            .then(() => {
                success()
            })
            .catch(e => {
                error('Update failed!')
            });
    },

    getOrders: (success, error) => {
        db.collection("orders")
            .orderBy("updated_on", 'desc')
            .get()
            .then(querySnapshot => {
                const orders = querySnapshot.docs.map(doc => doc.data());
                success(orders)
            })
            .catch(e => {
                error('Load failed!')
            });
    },

    countBrunchOrders: (dateTime, success, error) => {
        console.log('countOrders for: '+dateTime);
        db.collection("orders")
            .where('dateTime', "==", dateTime)
            .get()
            .then(querySnapshot => {
                console.log('results: '+querySnapshot.docs.length);
                let count = 0
                const orders = querySnapshot.docs.map(doc => doc.data());
                for (let i = 0; i < orders.length; i++) {
                    const order = orders[i];
                    count = count + parseInt(order.quantity+'')
                }
                success(count)
            })
            .catch(e => {
                error('Load failed!')
            });
    },

}

const apiCall = (url, params, method, headers, onSuccess, onError) => {
    // console.log('url: ' + url);
    fetch(url, {
        method: method,
        headers: headers,
        body: params
    })
        .then(response => response.json())
        .then(result => {
            // console.log(JSON.stringify(result));
            onSuccess(result);
        })
        .catch(err => {
            console.log(err)
            onError(err);
        });
};

export default Api;
