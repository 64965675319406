import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import React, { Component } from "react";

class MapContainer extends Component {
    render() {
        return (
            <Map
                initialCenter={{
                    lat: parseFloat(this.props.city.lat),
                    lng: parseFloat(this.props.city.lng)
                }}
                google={this.props.google}
                zoom={14}
                containerStyle={{ position: 'relative', height: 120, width: 300, alignSelf: 'center' }}>
                <Marker
                    onClick={() => { }}
                    name={'Current location'} />
            </Map>
        );
    }
}

const GoogleMap = GoogleApiWrapper({
  apiKey: 'AIzaSyDrSDFvlawcMv4mF9t1R1LA5g_46fPAlvg',
})(MapContainer);

export default GoogleMap;