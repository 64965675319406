import React, { useState, useEffect } from 'react';
import '../../App.css';
import { Link, useHistory } from 'react-router-dom';
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import MyStorage from '.././util/storage'
import background from '../../assets/bg.png';
import home from '../../assets/home2.png';
import showToast from '.././util/toast'
import Loader from 'react-loader-spinner'
import { ToastContainer } from 'react-toastify';
import Api from '../api/api'

const AdminEvents = () => {
  const history = useHistory()
  const [models, setModels] = useState([])
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    loadData()
  }, [])

  if (!MyStorage.isLogin()) {
    console.log('not logged in');
    history.replace('/login')
    return null
  }

  const loadData = () => {
    setLoader(true)
    Api.getEvents(
      (response) => {
        setLoader(false)
        setModels(response)
        console.log('orders: ' + response.length);
      },
      (error) => {
        setLoader(false)
      });
  };

  const editEvent = (event) => {
    history.push({
      pathname: '/admin-add-event',
      state: { model: event }
    })
  }

  const deleteEvent = (event) => {
    if (window.confirm('Are you sure you want to delete this event?')) {
      setLoader(true)
      Api.deleteEvent(event,
        () => {
          setLoader(false)
          const temp = models.filter((item, j) => {
            return item.title !== event.title; //false will delete
          });
          setModels(temp)
        },
        (error) => {
          setLoader(false)
        });
    }
  };

  return (
    <div style={{ marginTop: 0, marginLeft: 0, marginRight: 0, padding: 0 }}>
      <img
        src={background}
        style={{ position: 'absolute', width: '100%', height: '100vh', objectFit: 'cover' }} />
      <div
        style={{ position: 'absolute', width: '100%', display: 'flex', flexDirection: 'column', padding: 0 }}>
        <div style={{ padding: 30, display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'scroll' }}>
          <h3 style={{ color: 'white', alignSelf: 'center' }}>Events</h3>
          <Button
            className='btn-block btn-dark'
            style={{ marginTop: 10, fontSize: 18, fontWeight: 'bold', width: '100%', backgroundColor: 'green', borderWidth: 0, height: 40, width: 300, alignSelf: 'center' }}
            onClick={() => {
              history.push({
                pathname: '/admin-add-event',
                state: { model: null }
              })
            }}>
            Add Event
          </Button>
          <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
            {models.map((item, index) => {
              return (
                <div style={{ width: '100%', backgroundColor: 'white', marginTop: 10, padding: 10, width: 300, marginRight: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                  <div style={{ fontSize: 20, display: 'flex', fontWeight: 'bold' }}>{item.title}</div>
                  <div style={{ fontSize: 20, display: 'flex' }}>{item.date}</div>
                  <div>
                    <Button
                      className='btn-block btn-dark'
                      style={{ marginTop: 10, fontSize: 18, marginRight: 10, fontWeight: 'bold', width: '100%', backgroundColor: 'orange', borderWidth: 0, height: 40, width: 100 }}
                      onClick={() => {
                        editEvent(item)
                      }}>
                      Edit
                    </Button>
                    <Button
                      className='btn-block btn-dark'
                      style={{ marginTop: 10, fontSize: 18, fontWeight: 'bold', width: '100%', backgroundColor: 'red', borderWidth: 0, height: 40, width: 100 }}
                      onClick={() => {
                        deleteEvent(item)
                      }}>
                      Delete
                    </Button>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      {loader ?
        <div style={{ position: 'absolute', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          <div style={{ padding: 10, backgroundColor: 'red', borderRadius: 100 }}>
            <Loader
              type="ThreeDots"
              color="white"
              height={70}
              width={70}
              visible={true} />
          </div>
        </div>
        :
        null
      }

      <button
        onClick={() => {
          window.location.replace('/admin-dashboard')
        }}
        className='home-btn'>
        <img
          src={home}
          className='home-btn-img' />
      </button>
    </div>
  );
}

export default AdminEvents;