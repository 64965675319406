import React, { Component } from 'react';
import Slider from 'react-slick';
import db, { storage } from './firebase/firebase';
import Loader from 'react-loader-spinner'
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import { getMobileView } from './util/mobile_view';
import background from '../assets/bg.png';
import logo from '../assets/logo1.png';
import plate from '../assets/plate.png';
import juice from '../assets/juice.png';
import home from '../assets/home2.png';
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from 'emailjs-com';
import showToast from './util/toast'
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import CreditCardInput from 'react-credit-card-input';
import GoogleMap from './map'
import Api from './api/api'
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';
import Popup from './popup'

class Register extends Component {

    state = {
        name: '',
        email: '',
        dietryRes: '',
        quantity: '1',
        loader: false,
        models: [],
        selModel: null,
        orders: 0,

        paypalUrl: '',

        cardNumber: '',
        expiry: '',
        cvc: ''
    }

    render() {
        return (
            <div style={{ marginTop: 0, marginLeft: 0, marginRight: 0, padding: 0 }}>
                <img
                    src={background}
                    style={{ position: 'absolute', width: '100%', height: '100vh', objectFit: 'cover' }} />
                <div
                    style={{ position: 'absolute', width: '100%', display: 'flex', flexDirection: 'column', padding: 0 }}>
                    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'scroll', height: '100vh', }}>
                        <div style={{ display: 'flex' }}>
                            <img
                                src={plate}
                                className='plate-juice' />
                            <img
                                src={logo}
                                className='logo-title' />
                            <img
                                src={juice}
                                className='plate-juice' />
                        </div>
                        <Form onSubmit={this.sendEmailToClient} className='form' style={{ marginTop: 10, padding: 20, backgroundColor: 'rgba(0,0,0,0.2)', borderRadius: 10, display: 'flex', flexDirection: 'column' }}>
                            <h5 style={{ alignSelf: 'center', color: 'white' }}>
                                Register for Brunch Here!
                            </h5>
                            <FormGroup style={{ marginTop: 5, display: 'flex' }}>
                                <Input
                                    name="from_fname"
                                    style={{ marginRight: 10 }}
                                    type='text'
                                    placeholder='Name'
                                    value={this.state.name}
                                    onChange={(event) => {
                                        console.log('change: ' + event.target.value);
                                        this.setState({ name: event.target.value })
                                    }} />
                                <Input
                                    name="from_email"
                                    style={{ fontSize: 18 }}
                                    type='email'
                                    placeholder='Email'
                                    value={this.state.email}
                                    onChange={(event) => {
                                        console.log('change: ' + event.target.value);
                                        this.setState({ email: event.target.value })
                                    }} />
                            </FormGroup>
                            <FormGroup style={{ marginTop: 10 }}>
                                <Input
                                    name="message"
                                    style={{ fontSize: 18 }}
                                    type='textarea'
                                    placeholder='Dietry Restrictions (If Any) ....'
                                    value={this.state.dietryRes}
                                    onChange={(event) => {
                                        console.log('change: ' + event.target.value);
                                        this.setState({ dietryRes: event.target.value })
                                    }} />
                            </FormGroup>

                            <h6 style={{ display: 'flex', alignSelf: 'center', marginTop: 10, color: 'white' }}>
                                Choose Brunch Schedule
                            </h6>
                            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                                {this.state.models.map((item) => {
                                    return (
                                        <button
                                            type="button"
                                            onClick={() => {
                                                this.setState({ selModel: item })
                                                this.loadBrunchOrders(item)
                                            }}
                                            style={{ width: 210, alignSelf: 'center', backgroundColor: this.state.selModel.id === item.id ? 'blue' : 'white', color: this.state.selModel.id === item.id ? 'white' : 'blue', borderRadius: 5, height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 10 }}>
                                            {item.dateTime}
                                        </button>
                                    )
                                })}
                            </div>


                            <h6 style={{ display: 'flex', alignSelf: 'center', marginTop: 15, color: 'white' }}>
                                No. of Seats
                            </h6>
                            <FormGroup style={{ marginTop: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <button
                                    type="button"
                                    onClick={() => {
                                        let q = parseInt(this.state.quantity)
                                        q++
                                        this.setState({ quantity: q })
                                    }}
                                    style={{ borderWidth: 0, width: 40, height: 40, borderRadius: 25, backgroundColor: 'green', color: 'white', fontSize: 26, display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold' }}>
                                    +
                                </button>
                                <Input
                                    name="from_quantity"
                                    style={{ fontSize: 18, width: 100, marginLeft: 10, marginRight: 10, textAlign: 'center' }}
                                    type='number'
                                    placeholder=''
                                    value={this.state.quantity}
                                    onChange={(event) => {
                                        console.log('change: ' + event.target.value);
                                        this.setState({ quantity: event.target.value })
                                    }} />
                                <button
                                    type="button"
                                    onClick={() => {
                                        let q = parseInt(this.state.quantity)
                                        q--
                                        if (q < 1) {
                                            q = 1
                                        }
                                        this.setState({ quantity: q })
                                    }}
                                    style={{ borderWidth: 0, width: 40, height: 40, borderRadius: 25, backgroundColor: 'red', color: 'white', fontSize: 26, display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold' }}>
                                    -
                                </button>
                            </FormGroup>
                            {this.state.selModel != null ?
                                <div style={{ display: 'flex', alignSelf: 'center', marginTop: 15, fontSize: 18, color: 'white', fontWeight: 'bold' }}>
                                    ${this.state.selModel.price} x {this.state.quantity} = ${this.state.selModel.price * parseInt(this.state.quantity)}
                                </div>
                                :
                                null
                            }

                            {!this.checkLimit() ?
                                <div style={{ color: 'red', alignSelf:'center', backgroundColor:'white', padding:5 }}>
                                    Sorry! Registrations limit is reached.
                                </div>
                                :
                                <FormGroup style={{ marginTop: 15, display: 'flex', flexDirection: 'column' }}>
                                    <button
                                        onClick={this.payViaPayPal}
                                        type="button"
                                        className='btn-block btn-dark'
                                        style={{ height: 50, fontSize: 18, fontWeight: 'bold', width: '100%', backgroundColor: '#066db4', borderWidth: 0, borderRadius: 5 }}>
                                        Pay via PayPal
                                    </button>
                                    <div style={{ marginTop: 10, alignSelf: 'center', flex: 1 }}>
                                        <CreditCardInput
                                            cardNumberInputProps={{ value: this.state.cardNumber, onChange: (e) => { this.setState({ cardNumber: e.target.value }) } }}
                                            cardExpiryInputProps={{ value: this.state.expiry, onChange: (e) => { this.setState({ expiry: e.target.value }) } }}
                                            cardCVCInputProps={{ value: this.state.cvc, onChange: (e) => { this.setState({ cvc: e.target.value }) } }}
                                            fieldClassName="input"
                                        />
                                    </div>
                                    <button
                                        onClick={this.payViaCard}
                                        type="button"
                                        className='btn-block btn-dark'
                                        style={{ marginTop: 10, height: 50, fontSize: 18, fontWeight: 'bold', width: '100%', backgroundColor: '#130c9a', borderWidth: 0, borderRadius: 5 }}>
                                        Pay via Card
                                    </button>
                                </FormGroup>
                            }

                            {/* <div style={{ width: 212, alignSelf: 'center', marginTop: 0, backgroundColor: 'white', borderRadius: 5, height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div>{this.state.dateTime}</div>
                            </div> */}

                            <h6 style={{ display: 'flex', alignSelf: 'center', marginTop: 10, color: 'white' }}>
                                Brunch Location
                            </h6>
                            {this.state.selModel == null ?
                                null
                                :
                                <div
                                    style={{ width: 300, alignSelf: 'center', fontSize: 16, backgroundColor: '#000', borderRadius: 0, padding: 5, textAlign: 'center', color: 'white' }}
                                >
                                    {this.state.selModel.city.address}
                                </div>
                            }
                            {this.state.selModel == null ?
                                null
                                :
                                <div
                                    className='margin-bottom'>
                                    <GoogleMap
                                        city={this.state.selModel.city} />
                                </div>
                            }

                        </Form>
                    </div>
                </div>
                {this.state.loader ?
                    <div style={{ position: 'absolute', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                        <div style={{ padding: 10, backgroundColor: 'red', borderRadius: 100 }}>
                            <Loader
                                type="ThreeDots"
                                color="white"
                                height={70}
                                width={70}
                                visible={true} />
                        </div>
                    </div>
                    :
                    null
                }
                <div>
                    <ToastContainer />
                </div>

                <button
                    onClick={() => {
                        window.location.replace('/')
                    }}
                    className='home-btn'>
                    <img
                        src={home}
                        className='home-btn-img' />
                </button>

            </div>
        )
    }

    randomFixedNumber = (length) => {
        return Math.floor(Math.pow(10, length - 1) + Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1));
    }

    checkEmail = (email) => {
        let emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return emailReg.test(email);
    }

    payViaPayPal = () => {
        if (!this.checkFormFilled()) {
            return
        }

        const { name, email, dietryRes, quantity, selModel } = this.state
        let order = {
            orderId: this.randomFixedNumber(6),
            name: name,
            email: email,
            dietryRes: dietryRes,
            city: selModel.city,
            quantity: quantity,
            dateTime: selModel.dateTime,
        }

        localStorage.setItem('order', JSON.stringify(order))

        this.setState({ loader: true });
        Api.getCredentials(
            (credentials) => {
                Api.payViaPayPal(credentials.paypal.username, credentials.paypal.password, this.state,
                    (response) => {
                        this.setState({ loader: false });
                        let id = response.id
                        if (id !== undefined) {
                            let links = response.links
                            for (let i = 0; i < links.length; i++) {
                                const link = links[i];
                                if (link.rel === 'approval_url') {
                                    let url = link.href
                                    this.setState({ paypalUrl: url })
                                    console.log('paypalUrl: ' + url);
                                    // load url
                                    window.location = url
                                }
                            }
                        }
                        else {
                            showToast('error', 'Payment failed!')
                        }
                    },
                    (error) => {
                        this.setState({ loader: false });
                        console.log(error);
                    }
                )
            },
            (error) => {
                this.setState({ loader: true });
                showToast('error', error)
            }
        )
    };

    checkFormFilled = () => {
        const { name, email, selModel } = this.state
        if (name === '' || email === '' || selModel == null) {
            showToast('error', 'Please fill complete form!')
            return false
        }
        if (!this.checkEmail(email)) {
            showToast('error', 'Invalid Email!')
            return false
        }
        if (!selModel.city) {
            showToast('error', 'Date error!')
            return false
        }

        let time = moment(selModel.dateTime, 'MM-DD-YYYY h:m a')
        let current = moment()
        let diff = time - current
        if (diff < (24 * 60 * 60 * 1000)) {
            showToast('error', 'Sorry! no more registrations available for this date.')
            return false
        }

        // check limit
        if (!this.checkLimit) {
            showToast('error', 'Sorry! Registrations limit is reached.')
            return false
        }

        return true
    };

    checkLimit = () => {
        if (this.state.selModel == null) return true

        let limit = parseInt(this.state.selModel.limit + '')
        // let limit = 2
        let count = this.state.orders

        if (limit <= count) {
            return false
        }
        return true
    };

    payViaCard = () => {
        if (!this.checkFormFilled()) {
            return
        }

        const { cardNumber, expiry, cvc, quantity, selModel } = this.state
        if (cardNumber === '' || expiry === '' || cvc === '') {
            showToast('error', 'Please fill card info!')
            return
        }

        this.setState({ loader: true });
        Api.getCredentials(
            (credentials) => {
                if (credentials.stripe) {
                    let key = credentials.stripe.s_key
                    console.log('key: ' + key);
                    Api.stripeToken(key, cardNumber, expiry.split('/')[0], '20' + expiry.split('/')[1], cvc,
                        (response) => {
                            let token = response.id
                            console.log('token: ' + token);
                            if (token != undefined && token != null && token !== '') {
                                Api.stripePayment(key, token, (selModel.price * parseInt(quantity)),
                                    (response) => {
                                        this.setState({ loading: false });
                                        console.log('status: ' + response.status);
                                        if (response.status.toLowerCase() === 'succeeded') {
                                            showToast('success', 'Payment Success!')
                                            this.placeOrder()
                                        }
                                    },
                                    (error) => {
                                        this.setState({ loader: false });
                                        showToast('error', 'Payment failed!')
                                    });
                            }
                            else {
                                this.setState({ loader: false });
                                showToast('error', 'Payment failed!')
                            }
                        },
                        (error) => {
                            this.setState({ loader: false });
                            showToast('error', 'Payment failed!')
                        });
                }
                else {
                    this.setState({ loader: false });
                    showToast('error', 'Payment failed!')
                }
            },
            (error) => {
                this.setState({ loader: true });
                showToast('error', error)
            }
        )
    };

    placeOrder = () => {
        const { name, email, dietryRes, quantity, selModel } = this.state
        let order = {
            orderId: this.randomFixedNumber(6),
            name: name,
            email: email,
            dietryRes: dietryRes,
            city: selModel.city,
            quantity: quantity,
            dateTime: selModel.dateTime
        }

        localStorage.setItem('order', JSON.stringify(order))
        const o = JSON.parse(localStorage.getItem('order'))
        console.log('order: ' + JSON.stringify(o));

        this.setState({ loader: true });
        Api.placeOrder(order,
            (response) => {
                this.setState({ loader: false });
                // reset info
                this.setState({
                    name: '',
                    email: '',
                    dietryRes: '',
                    quantity: '1',
                    selModel: this.state.models[0],
                    loader: false,
                    cardNumber: '',
                    expiry: '',
                    cvc: ''
                });
                localStorage.setItem('orderId', order.orderId)
                window.location.replace('/success')
            },
            (error) => {
                this.setState({ loader: false });
                showToast('error', 'Order Failed!')
            });
    };

    placePayPalOrder = () => {
        const order = JSON.parse(localStorage.getItem('order'))
        // console.log('order: ' + JSON.stringify(order));
        this.setState({ loader: true });
        Api.placeOrder(order,
            (response) => {
                this.setState({ loader: false });
                localStorage.setItem('orderId', order.orderId)
                window.location.replace('/success')
            },
            (error) => {
                this.setState({ loader: false });
                showToast('error', 'Order Failed!')
            });
    };

    componentDidMount = () => {
        console.log('MOUNT');
        console.log('history: ' + JSON.stringify(this.props.history));
        console.log('location: ' + window.location.href);
        if (window.location.href.includes('?paymentId')) {
            this.placePayPalOrder()
            return
        }
        this.loadSettings()
    };

    loadSettings = () => {
        this.setState({ loader: true });
        Api.getSettings(
            (settings) => {
                let models = settings.settings
                this.setState({ loader: false, models: models, selModel: models.length > 0 ? models[0] : null });
                this.loadBrunchOrders(models.length > 0 ? models[0] : null)
                // this.setState({ loader: false, city: settings.city, price: settings.price, dateTime: settings.dateTime, selDate: settings.dateTime.length > 0 ? settings.dateTime[0] : null });
            },
            (error) => {
                this.setState({ loader: false });
            });
    };

    loadBrunchOrders = (item) => {
        if (item == null) return;

        console.log('loadBrunchLimit');
        Api.countBrunchOrders(item.dateTime,
            (count) => {
                this.setState({ orders: count })
            },
            (error) => {
                this.setState({ loader: false });
            });
    };
}

export default Register