import React, { Component } from 'react';
import background from '../assets/bg.png';
import done from '../assets/done.png';
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import { sendEmailToClient, sendEmailToAdmin } from './util/email'
import home from '../assets/home2.png';

class Success extends Component {

    state = {}

    render() {
        return (
            <div style={{ marginTop: 0, marginLeft: 0, marginRight: 0, padding: 0 }}>
                <img
                    src={background}
                    style={{ position: 'absolute', width: '100%', height: '100vh', objectFit: 'cover' }} />
                <div
                    style={{ position: 'absolute', width: '100%', display: 'flex', flexDirection: 'column', padding: 0 }}>
                    <div style={{ marginTop: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                        <Form onSubmit={this.sendEmail} className='form' style={{ padding: 20, backgroundColor: 'rgba(255,255,255,1)', borderRadius: 10, display: 'flex', flexDirection: 'column' }}>
                            <img
                                src={done}
                                style={{ width: 100, height: 100, objectFit: 'contain', alignSelf: 'center' }} />

                            <h5 style={{ alignSelf: 'center', color: '#519907', fontWeight: 'bold', marginTop: 10 }}>
                                Thank You!
                            </h5>
                            <div style={{ alignSelf: 'center', textAlign: 'center', fontSize: 17 }}>
                                Your seat(s) booked for brunch! Please check your email for booking details.
                            </div>
                            <div style={{ marginTop: 20, alignSelf: 'center', textAlign: 'center', fontWeight: 'bold', fontSize: 16 }}>
                                Booking ID: {localStorage.getItem('orderId')}
                            </div>
                            <button
                                onClick={() => {
                                    window.location.replace('/')
                                }}
                                type="button"
                                className='btn-block btn-dark'
                                style={{ alignSelf: 'center', marginTop: 20, height: 40, fontSize: 18, fontWeight: 'bold', width: 300, backgroundColor: 'orange', borderWidth: 0, borderRadius: 5 }}>
                                Back to Home
                            </button>
                        </Form>
                    </div>
                </div>

                <button
                    onClick={() => {
                        window.location.replace('/')
                    }}
                    className='home-btn'>
                    <img
                        src={home}
                        className='home-btn-img' />
                </button>
            </div>
        )
    }

    componentDidMount = () => {
        console.log('history: ' + JSON.stringify(this.props.history));

        // send order success mails
        sendEmailToClient(JSON.parse(localStorage.getItem('order')))
        sendEmailToAdmin(JSON.parse(localStorage.getItem('order')))
    };
}

export default Success