import React, { useState, useEffect } from 'react';
import '../../App.css';
import { Link, useHistory } from 'react-router-dom';
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import MyStorage from '.././util/storage'
import background from '../../assets/bg.png';

const AdminDashboard = () => {
  const history = useHistory()

  if (!MyStorage.isLogin()) {
    console.log('not logged in');
    history.replace('/login')
    return null
  }

  return (
    <div style={{ marginTop: 0, marginLeft: 0, marginRight: 0, padding: 0 }}>
      <img
        src={background}
        style={{ position: 'absolute', width: '100%', height: '100vh', objectFit: 'cover' }} />
      <div
        style={{ position: 'absolute', width: '100%', display: 'flex', flexDirection: 'column', padding: 0 }}>
        <div style={{ marginTop: 30, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height:'100vh' }}>
          <Form className='form' style={{ padding: 20, backgroundColor: 'white', borderRadius: 10, width:450 }}>
            <Button
              className='btn-block btn-dark'
              style={{ marginTop: 0, fontSize: 18, fontWeight: 'bold', width: '100%', backgroundColor: 'orange', borderWidth: 0, height:50 }}
              onClick={() => {
                history.push('/admin-orders')
              }}>
              Orders
            </Button>
            <Button
              className='btn-block btn-dark'
              style={{ marginTop: 15, fontSize: 18, fontWeight: 'bold', width: '100%', backgroundColor: 'orange', borderWidth: 0, height:50 }}
              onClick={() => {
                history.push('/admin-settings')
              }}>
              Settings
            </Button>
            <Button
              className='btn-block btn-dark'
              style={{ marginTop: 15, fontSize: 18, fontWeight: 'bold', width: '100%', backgroundColor: 'orange', borderWidth: 0, height:50 }}
              onClick={() => {
                history.push('/admin-events')
              }}>
              Events
            </Button>
            <Button
              className='btn-block btn-dark'
              style={{ marginTop: 15, fontSize: 18, fontWeight: 'bold', width: '100%', backgroundColor: 'orange', borderWidth: 0, height:50 }}
              onClick={() => {
                history.push('/admin-gallery')
              }}>
              Gallery
            </Button>
            <Button
              className='btn-block btn-dark'
              style={{ marginTop: 15, fontSize: 18, fontWeight: 'bold', width: '100%', backgroundColor: 'orange', borderWidth: 0, height:50 }}
              onClick={() => {
                history.push('/admin-vendors')
              }}>
              Vendors
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;