import React, { useState, useEffect, useRef } from 'react';
import '../../App.css';
import { useLocation, useHistory } from 'react-router-dom';
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import MyStorage from '.././util/storage'
import background from '../../assets/bg.png';
import Loader from 'react-loader-spinner'
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';
import home from '../../assets/home2.png';
import { ToastContainer } from 'react-toastify';
import showToast from '.././util/toast'
import db, { storage } from '.././firebase/firebase';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import add from '../../assets/add.png';
import cross from '../../assets/cross.png';

let i = 1
let logoUrl = ''
let imageUrls = []

const AdminAddVendor = () => {
  const history = useHistory()
  const location = useLocation()

  const model = location.state.model
  const editMode = model != null

  const [bname, setBname] = useState(editMode ? model.bname : '');
  const [cname, setCname] = useState(editMode ? model.cname : '');
  const [phone, setPhone] = useState(editMode ? model.phone : '');
  const [email, setEmail] = useState(editMode ? model.email : '');
  const [website, setWebsite] = useState(editMode ? model.website : '');
  const [detail, setDetail] = useState(editMode ? model.detail : '');

  const [mainShot, setMainShot] = useState(null);
  const [mainShotUrl, setMainShotUrl] = useState(editMode ? model.logo : null);
  const [loader, setLoader] = useState(false);
  const addMainShot = useRef(null);
  const addScreenshot = useRef(null);
  const [images, setImages] = useState([{}]);

  useEffect(() => {
    if (editMode) {
      let images = [{}];
      for (let i = 0; i < model.images.length; i++) {
        images = images.concat({
          file: null,
          url: model.images[i],
          name: '',
        })
        if (i === model.images.length - 1)
          setImages(images);
      }
    }
  }, [])

  if (!MyStorage.isLogin()) {
    console.log('not logged in');
    history.replace('/login')
    return null
  }

  const addNewVendor = () => {
    if (bname == '' || cname === '' || phone === '' || email === '' || detail === '') {
      showToast('error', 'Fill complete form!')
      return
    }

    if (mainShot === null || images.length === 1) {
      showToast('error', 'Fill complete form!')
      return
    }

    setLoader(true);
    console.log('add logo');
    uploadPhoto(
      'products/' + mainShot.name,
      mainShot,
      (url) => {
        console.log('logoUrl: ' + url);
        console.log('add images');
        logoUrl = url
        i = 1
        imageUrls = []
        uploader()
      }
    );

  };

  const editNewModel = () => {
    if (bname == '' || cname === '' || phone === '' || email === '' || detail === '') {
      showToast('error', 'Fill complete form!')
      return
    }

    if (images.length === 1) {
      showToast('error', 'No Images!')
      return
    }

    setLoader(true);

    // update logo
    if (mainShot !== null) {
      console.log('update logo');
      uploadPhoto(
        'products/' + mainShot.name,
        mainShot,
        (url) => {
          // update main shot info
          db.collection('vendors').doc(model.id).set({ logo: url }, { merge: true });
        }
      );
    }

    // update images
    i = 1
    imageUrls = []
    updater()

    // update info
    db.collection('vendors')
      .doc(model.id)
      .set({
        bname: bname,
        cname: cname,
        phone: phone,
        email: email,
        website: website,
        detail: detail,
      }, { merge: true })
      .then(() => {
        setLoader(false);
        showToast('success', 'Product Saved!')
      })
      .catch(error => {
        showToast('error', 'Save Failed!');
      });
  };

  const uploader = () => {
    const image = images[i];
    uploadPhoto(
      'products/' + image.file.name,
      image.file,
      (imageUrl) => {
        console.log('imageUrl: ' + imageUrl);
        imageUrls.push(imageUrl)
        console.log('image added');
        if (i === images.length - 1) {
          console.log('add vendor');
          let vendor = {
            bname: bname,
            cname: cname,
            phone: phone,
            email: email,
            website: website,
            detail: detail,
            logo: logoUrl,
            images: imageUrls,
          };
          db.collection('vendors')
            .add(vendor)
            .then(() => {
              setLoader(false);
              showToast('success', 'Product Saved!')
            })
            .catch(error => {
              showToast('error', 'Save Failed!');
            });
        }
        else {
          console.log('next upload');
          i++
          uploader()
        }
      }
    );
  };

  const updater = () => {
    const image = images[i];
    if (image.file == null) {
      console.log('already uploaded');
      imageUrls.push(image.url)
      if (i === images.length - 1) {
        updateVendorImageUrls()
      }
      else {
        console.log('next upload');
        i++
        updater()
      }
      return
    }
    uploadPhoto(
      'products/' + image.file.name,
      image.file,
      (imageUrl) => {
        console.log('imageUrl: ' + imageUrl);
        imageUrls.push(imageUrl)
        console.log('image added');
        if (i === images.length - 1) {
          console.log('update vendor');
          updateVendorImageUrls()
        }
        else {
          console.log('next upload');
          i++
          updater()
        }
      }
    );
  };

  const updateVendorImageUrls = () => {
    console.log('imageUrls: '+imageUrls.length);
    db.collection('vendors')
      .doc(model.id)
      .set({
        images: imageUrls
      }, { merge: true })
  };

  const uploadPhoto = (path, photo, onSuccess) => {
    const uploadTask = storage.ref(path).put(photo);
    uploadTask.on('state_changed',
      (snapshot) => {
        // const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      },
      (error) => {
        console.log(error);
      },
      () => {
        storage.ref(path).getDownloadURL().then((url) => {
          onSuccess(url);
        })
      });
  };

  const handleAddImage = (event) => {
    let temp = [{}];
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      let url = URL.createObjectURL(file);
      temp = temp.concat({ file: file, url: url, name: file.name });
    }
    setImages(temp);
  };

  const showProductImages = () => {
    return images.map((item, index) => {
      if (index == 0) {
        return (
          <div style={{ marginRight: 10, marginBottom: 10 }}>
            <div onClick={() => {
              // browse screenshot
              addScreenshot.current.click();
            }} style={{ width: 100, height: 100, backgroundColor: 'gray', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img src={add} style={{ width: 30, height: 30 }} />
              <input
                multiple
                type='file'
                style={{ display: 'none' }}
                ref={addScreenshot}
                onChange={handleAddImage}
              />
            </div>
          </div>
        );
      }
      return (
        <div style={{ display: 'inline-flex', width: 100, height: 100, marginRight: 10, marginBottom: 10 }}>
          <img src={item.url} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
          <div onClick={() => {
            // delete screenshot
            const temp = images.filter((item, j) => {
              return index !== j; //false will delete
            });
            setImages(temp);
          }} style={{ width: 25, height: 25, marginLeft: -25, marginTop: 0, backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={cross} style={{ width: 20, height: 20 }} />
          </div>
        </div>
      );
    });
  }

  return (
    <div style={{ marginTop: 0, marginLeft: 0, marginRight: 0, padding: 0 }}>
      <img
        src={background}
        style={{ position: 'absolute', width: '100%', height: '100vh', objectFit: 'cover' }} />
      <div
        style={{ position: 'absolute', width: '100%', display: 'flex', flexDirection: 'column', padding: 0 }}>
        <div style={{ paddingTop: 30, paddingBottom: 100, display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh', overflow: 'scroll' }}>
          <h3 style={{ color: 'white', alignSelf: 'center' }}>Add Vendor</h3>
          <Form className='form' style={{ padding: 20, backgroundColor: 'white', borderRadius: 10, width: 450 }}>

            <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
              <img
                src={mainShotUrl}
                style={{ width: 120, height: 100, marginBottom: 10, marginTop: 20, objectFit: 'contain', backgroundColor: '#f2f2f2' }} />
              <Button
                style={{ marginTop: 0, fontSize: 16 }}
                className='btn-block'
                onClick={() => {
                  addMainShot.current.click();
                }}>
                Choose Logo
              </Button>
              <FormGroup style={{ marginTop: 20, width: '100%' }}>
                <Input
                  style={{ fontSize: 18 }}
                  type='text'
                  placeholder='Business Name'
                  value={bname}
                  onChange={(event) => {
                    console.log('change: ' + event.target.value);
                    setBname(event.target.value)
                  }} />
              </FormGroup>
              <FormGroup style={{ marginTop: 10, width: '100%' }}>
                <Input
                  style={{ fontSize: 18 }}
                  type='text'
                  placeholder='Contact Name'
                  value={cname}
                  onChange={(event) => {
                    console.log('change: ' + event.target.value);
                    setCname(event.target.value)
                  }} />
              </FormGroup>
              <FormGroup style={{ marginTop: 10, width: '100%' }}>
                <Input
                  style={{ fontSize: 18 }}
                  type='text'
                  placeholder='Phone Number'
                  value={phone}
                  onChange={(event) => {
                    console.log('change: ' + event.target.value);
                    setPhone(event.target.value)
                  }} />
              </FormGroup>
              <FormGroup style={{ marginTop: 10, width: '100%' }}>
                <Input
                  style={{ fontSize: 18 }}
                  type='text'
                  placeholder='Email'
                  value={email}
                  onChange={(event) => {
                    console.log('change: ' + event.target.value);
                    setEmail(event.target.value)
                  }} />
              </FormGroup>
              <FormGroup style={{ marginTop: 10, width: '100%' }}>
                <Input
                  style={{ fontSize: 18 }}
                  type='text'
                  placeholder='Website Link (optional)'
                  value={website}
                  onChange={(event) => {
                    console.log('change: ' + event.target.value);
                    setWebsite(event.target.value)
                  }} />
              </FormGroup>
              <FormGroup style={{ marginTop: 10, width: '100%' }}>
                <Input
                  style={{ fontSize: 18 }}
                  type='text'
                  placeholder='Breif Detail'
                  value={detail}
                  onChange={(event) => {
                    console.log('change: ' + event.target.value);
                    setDetail(event.target.value)
                  }} />
              </FormGroup>

              <FormGroup style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
                <div>Product Images:</div>
                <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 5 }}>
                  {showProductImages()}
                </div>
              </FormGroup>

            </div>

            <input
              type='file'
              style={{ display: 'none' }}
              ref={addMainShot}
              onChange={(event) => {
                setMainShot(event.target.files[0]);
                setMainShotUrl(URL.createObjectURL(event.target.files[0]))
              }} />

            <Button
              className='btn-block btn-dark'
              style={{ marginTop: 15, fontSize: 18, fontWeight: 'bold', width: '100%', backgroundColor: 'orange', borderWidth: 0, height: 50 }}
              onClick={() => {
                if (!editMode)
                  addNewVendor()
                else
                  editNewModel()
              }}>
              Save Vendor
            </Button>

          </Form>
        </div>
      </div>

      {loader ?
        <div style={{ position: 'absolute', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          <div style={{ padding: 10, backgroundColor: 'red', borderRadius: 100 }}>
            <Loader
              type="ThreeDots"
              color="white"
              height={70}
              width={70}
              visible={true} />
          </div>
        </div>
        :
        null
      }

      <div>
        <ToastContainer />
      </div>

      <button
        onClick={() => {
          window.location.replace('/admin-dashboard')
        }}
        className='home-btn'>
        <img
          src={home}
          className='home-btn-img' />
      </button>

    </div>
  );
}

export default AdminAddVendor;