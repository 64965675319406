import React, { useState, useEffect } from 'react';
import '../App.css';
import { Link, useHistory, Redirect } from 'react-router-dom';
import { Input } from 'reactstrap';
import * as Strings from './strings'
import * as Colors from './colors'
import Api from './api/api'
import MyStorage from './util/storage';
import { Form, FormGroup, Button } from 'reactstrap';
import background from '../assets/bg1.png';
import food from '../assets/food2.png';
import invite from '../assets/invite.png';
import register from '../assets/register.png';
import contact from '../assets/contact.png';
import time from '../assets/time.png';
import calendar from '../assets/calendar.png';
import gallery from '../assets/gallery1.png';
import url from '../assets/url.png';
import moment from 'moment';

const Dashboard = () => {
    const history = useHistory();

    useEffect(() => {
        Api.randomLogin()
    }, [])

    return (
        <div>
            <img
                src={background}
                style={{ position: 'absolute', width: '100%', height: '100vh', objectFit: 'cover' }} />
            <img
                src={food}
                className='home-food' />
            <img
                src={url}
                className='home-url' />

            {/* <div style={{ position:'absolute', top:0, left:0, display: 'flex', flexDirection: 'column', height: '100vh', width:250, backgroundColor: 'pink',}}>
                <button
                    onClick={() => {
                        history.push('/gallery-collections')
                    }}
                    className='button home-gallery'>
                    <img
                        src={gallery}
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                </button>
            </div> */}

            {/* <button
                onClick={() => {
                    history.push('/events')
                }}
                className='button home-calendar'>
                <img
                    src={calendar}
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                <div
                    className='home-calendar-txt'>
                    {moment().format('DD')}
                </div>
            </button> */}

            <div
                style={{ position: 'absolute', bottom: 0, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
                className='home-bottom-parent'>
                {/* <img
                    src={time}
                    className='home-time' /> */}
                <img
                    src={invite}
                    className='home-invite' />
                <div style={{ display: 'flex' }}>
                    <button
                        onClick={() => {
                            history.push('/register')
                        }}
                        className='button'
                        style={{ padding: 0, backgroundColor: 'transparent', borderWidth: 0 }}>
                        <img
                            src={register}
                            className='home-button-img' />
                    </button>
                    <button
                        onClick={() => {
                            history.push('/contact')
                        }}
                        className='button'
                        style={{ padding: 0, backgroundColor: 'transparent', borderWidth: 0 }}>
                        <img
                            src={contact}
                            className='home-button-img' />
                    </button>
                </div>
                <div style={{ display: 'flex', marginTop: 5, width: '100%', alignItems: 'center', justifyContent: 'center', paddingLeft:20, paddingRight:20 }}>
                    <button
                        onClick={() => {
                            history.push('/events')
                        }}
                        className='button'
                        style={{ padding: 5, maxWidth: 200, flex: 1, backgroundColor: 'yellow', border: '1px solid blue', color: 'red', borderRadius: 50, fontSize: 20, fontWeight: 'bold' }}>
                        Calender
                    </button>
                    <button
                        onClick={() => {
                            history.push('/gallery-collections')
                        }}
                        className='button'
                        style={{ marginLeft: 10, padding: 5, maxWidth: 200, flex: 1.2, backgroundColor: 'yellow', border: '1px solid blue', color: 'red', borderRadius: 50, fontSize: 20, fontWeight: 'bold' }}>
                        Image Gallery
                    </button>
                    <button
                        onClick={() => {
                            history.push('/vendors')
                        }}
                        className='button'
                        style={{ marginLeft: 10, maxWidth: 200, padding: 5, flex: 1, backgroundColor: 'yellow', border: '1px solid blue', color: 'red', borderRadius: 50, fontSize: 20, fontWeight: 'bold' }}>
                        Vendors
                    </button>
                </div>
            </div>
        </div >
    );
}

export default Dashboard;