
const MyStorage = {

    isLogin: () => {
        let user = localStorage.getItem('user')
        return user != null
    },

    setUser: (user) => {
        if (user == null) {
            localStorage.removeItem('user')
            return
        }
        localStorage.setItem('user', JSON.stringify(user))
    },

    getUser: () => {
        let user = localStorage.getItem('user')
        if (user == null) {
            return user
        }
        else {
            return JSON.parse(user)
        }
    },

    setAllUsers: (obj) => {
        if (obj == null) {
            localStorage.removeItem('all_users')
            return
        }
        localStorage.setItem('all_users', JSON.stringify(obj))
    },

    getAllUsers: () => {
        let obj = localStorage.getItem('all_users')
        if (obj == null) {
            return []
        }
        else {
            return JSON.parse(obj)
        }
    },
}

export default MyStorage
