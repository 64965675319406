import emailjs from 'emailjs-com';

export const sendEmailToClient = (order) => {
    console.log('sendEmailToClient');
    if (order == null)
        return

    let params = {
        ...order,
        location: order.city.address,
        lat: order.city.lat,
        lng: order.city.lng,
    }
    console.log('PARAMS CLIENT: ' + JSON.stringify(params));

    emailjs.send('gmail', 'template_n3wqgls', params, '5yIURgbf9PsA9yLIR')
        .then((result) => {
            console.log(result);
        });
};

export const sendEmailToAdmin = (order) => {
    console.log('sendEmailToAdmin');
    if (order == null)
        return

    console.log('PARAMS ADMIN: ' + JSON.stringify(order));

    emailjs.send('gmail', 'template_6psdjws', order, '5yIURgbf9PsA9yLIR')
        .then((result) => {
            console.log(result);
        });
};
