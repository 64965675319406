import React, { useState, useEffect } from 'react';
import '../../App.css';
import { Link, useHistory } from 'react-router-dom';
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import MyLoader from '../util/loader'
import MyError from '../util/error'
import MyStorage from '../util/storage'
import Api from '../api/api'
import background from '../../assets/bg.png';
import { ToastContainer, toast } from 'react-toastify';

const Login = () => {
    const history = useHistory()
    const pin = '0347'
    const [password, setPassword] = useState('')
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState('');
    const [loader, setLoader] = useState(false);

    if (MyStorage.isLogin()) {
        console.log('already logged in');
        history.replace('/admin-dashboard')
        return null
    }

    const loginAdmin = () => {
        if (password === '') {
            return
        }

        setShowError(false)
        if (password === pin) {
            MyStorage.setUser({})
            history.replace('/admin-dashboard')
        }
        else {
            setError('Wrong pin!')
            setShowError(true)
        }
    };

    return (
        <div style={{ marginTop: 0, marginLeft: 0, marginRight: 0, padding: 0 }}>
            <img
                src={background}
                style={{ position: 'absolute', width: '100%', height: '100vh', objectFit: 'cover' }} />
            <div
                style={{ position: 'absolute', width: '100%', display: 'flex', flexDirection: 'column', padding: 0 }}>
                <div style={{ marginTop: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height:'100vh' }}>
                    <Form className='form' style={{ padding: 20, width:400,backgroundColor: 'white', borderRadius: 10, display:'flex', flexDirection:'column', alignItems:'center',justifyContent:'center' }}>
                        <h5 style={{}}>
                            Admin Login
                        </h5>
                        <FormGroup style={{ marginTop: 20, width:'100%' }}>
                            <Input
                                style={{ fontSize: 18 }}
                                type='text'
                                placeholder='Enter Pin'
                                value={password}
                                onChange={(event) => {
                                    console.log('change: ' + event.target.value);
                                    setPassword(event.target.value)
                                }} />
                        </FormGroup>
                        <MyError showError={showError} error={error} />
                        <Button
                            className='btn-block btn-dark'
                            style={{ marginTop: 20, fontSize: 18, fontWeight: 'bold', width: '100%', backgroundColor: 'orange', borderWidth: 0 }}
                            onClick={() => {
                                loginAdmin()
                            }}>
                            Login
                        </Button>
                    </Form>
                </div>
            </div>
            <div>
                <ToastContainer />
            </div>
        </div>
    );
}

export default Login; 