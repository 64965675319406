import React, { useState, useEffect, useRef } from 'react';
import '../../App.css';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import MyStorage from '.././util/storage'
import background from '../../assets/bg.png';
import home from '../../assets/home2.png';
import showToast from '.././util/toast'
import Loader from 'react-loader-spinner'
import db, { storage } from '.././firebase/firebase';

const AdminGalleryPhotos = () => {
    const history = useHistory()
    const location = useLocation()
    let event = location.state.event
    console.log(JSON.stringify(event));
    
    const [models, setModels] = useState(event.data)
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        //
    }, [])

    if (!MyStorage.isLogin()) {
        console.log('not logged in');
        history.replace('/login')
        return null
    }

    const deletePhoto = (photo) => {
        console.log('photo: '+JSON.stringify(photo));
        if (window.confirm('Are you sure you want to delete this photo?')) {
            db.collection("gallery")
                .where('image', '==', photo.image)
                .get()
                .then(querySnapshot => {
                    if (querySnapshot.docs.length > 0) {
                        let ID = querySnapshot.docs[0].id
                        console.log('ID: '+ID);
                        db.collection("gallery").doc(ID).delete().then(() => {
                            console.log('deleted');
                            const temp = models.filter((item, j) => {
                                return item.image !== photo.image; //false will delete
                            });
                            setModels(temp)
                        })
                    }
                    else {
                        showToast('error', 'Delete failed!')
                    }
                })
                .catch(e => {
                    showToast('error', 'Delete failed!')
                });
        }
    };

    return (
        <div style={{ marginTop: 0, marginLeft: 0, marginRight: 0, padding: 0 }}>
            <img
                src={background}
                style={{ position: 'absolute', width: '100%', height: '100vh', objectFit: 'cover' }} />
            <div
                style={{ position: 'absolute', width: '100%', display: 'flex', flexDirection: 'column', padding: 0 }}>
                <div style={{ padding: 30, display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'scroll' }}>
                    <h3 style={{ color: 'white', alignSelf: 'center' }}>{event.key}</h3>
                    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                        {models.map((item, index) => {
                            // console.log('image: ' + item.image);
                            return (
                                <div style={{ width: 200, backgroundColor: 'white', marginTop: 10, padding: 10, marginRight: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                    <img src={item.image} style={{ width: 180, height: 180, objectFit: 'cover' }} />
                                    <div>
                                        <Button
                                            className='btn-block btn-dark'
                                            style={{ marginTop: 10, fontSize: 14, fontWeight: 'bold', width: '100%', backgroundColor: 'red', borderWidth: 0, height: 35, width: 100 }}
                                            onClick={() => {
                                                deletePhoto(item)
                                            }}>
                                            Delete
                                        </Button>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            {loader ?
                <div style={{ position: 'absolute', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <div style={{ padding: 10, backgroundColor: 'red', borderRadius: 100 }}>
                        <Loader
                            type="ThreeDots"
                            color="white"
                            height={70}
                            width={70}
                            visible={true} />
                    </div>
                </div>
                :
                null
            }

            <button
                onClick={() => {
                    window.location.replace('/admin-dashboard')
                }}
                className='home-btn'>
                <img
                    src={home}
                    className='home-btn-img' />
            </button>
        </div>
    );
}

export default AdminGalleryPhotos;