import React, { Component } from 'react';
import Slider from 'react-slick';
import db, { storage } from './firebase/firebase';
import Loader from 'react-loader-spinner'
import { Link } from 'react-router-dom';
import background from '../assets/bg.png';
import logo from '../assets/logo_events.png';
import plate from '../assets/plate.png';
import juice from '../assets/juice.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Api from './api/api'
import home from '../assets/home2.png';
import Parser from 'html-react-parser';

class Events extends Component {

    state = {
        loader: false,
        models: []
    }

    render() {
        return (
            <div style={{ marginTop: 0, marginLeft: 0, marginRight: 0, padding: 0 }}>
                <img
                    src={background}
                    style={{ position: 'absolute', width: '100%', height: '100vh', objectFit: 'cover' }} />
                <div
                    style={{ position: 'absolute', width: '100%', display: 'flex', flexDirection: 'column', padding: 0 }}>
                    <div style={{ display: 'flex', alignSelf: 'center', marginTop: 30, marginBottom: 0 }}>
                        <img
                            src={plate}
                            className='plate-juice' />
                        <img
                            src={logo}
                            className='logo-title' />
                        <img
                            src={juice}
                            className='plate-juice' />
                    </div>

                    {!this.state.loader && this.state.models.length == 0 ?
                        <div style={{ alignSelf: 'center', padding: 20, backgroundColor: '#f2f2f2', marginTop: 50, borderRadius: 10, alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                            <div style={{ fontSize: 18 }}>
                                No Upcoming Events
                            </div>
                        </div>
                        :
                        null
                    }

                    {this.state.loader ?
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
                            <div style={{ padding: 10, backgroundColor: 'red', borderRadius: 100 }}>
                                <Loader
                                    type="ThreeDots"
                                    color="white"
                                    height={70}
                                    width={70}
                                    visible={true} />
                            </div>
                        </div>
                        :
                        null
                    }

                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', height: '85vh', overflow: 'scroll', paddingBottom: 140, backgroundColor: 'transparent', borderRadius: 20 }}>
                        {this.state.models.map((item, index) => {
                            return (
                                <div style={{ width: 300, height: 450, backgroundColor: '#f2f2f2', marginRight: 20, marginTop: 20, borderRadius: 10, alignItems: 'center', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                                    <img
                                        src={item.image}
                                        style={{ width: '100%', height: 200, objectFit: 'cover' }} />

                                    <div style={{ width: '100%', marginTop: 10, display: 'flex', flexDirection: 'column', flex: 2, alignItems: 'center', justifyContent: 'flex-start', overflow: 'scroll' }}>
                                        <h6 style={{ marginLeft: 20, marginRight: 20 }}>{item.title}</h6>
                                        <div style={{ color: 'gray', fontSize: 16, marginLeft: 20, marginRight: 20 }}>{Parser(item.detail)}</div>
                                    </div>

                                    <div style={{ fontSize: 18, fontWeight: 'bold', width: '100%', height: 50, backgroundColor: '#066db4', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white' }}>
                                        {item.date}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    
                </div>
                <div>
                    <ToastContainer />
                </div>

                <button 
                    onClick={()=>{
                        window.location.replace('/')
                    }}
                    className='home-btn'>
                    <img
                        src={home}
                        className='home-btn-img' />
                </button>

            </div>
        )
    }

    getDetail = (item)=>{
      
    };

    componentDidMount = () => {
        this.loadEvents()
    };

    loadEvents = () => {
        this.setState({ loader: true });
        Api.getEvents(
            (response) => {
                this.setState({ loader: false, models: response });
            },
            (error) => {
                this.setState({ loader: false });
            });
    };
}

export default Events;