import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/auth'
const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyDrSDFvlawcMv4mF9t1R1LA5g_46fPAlvg",
  authDomain: "",
  databaseURL: "",
  projectId: "caribbeanbruch",
  storageBucket: "gs://caribbeanbruch.appspot.com",
  messagingSenderId: "717861696220"
});
const db = firebaseApp.firestore();
export const storage = firebase.storage()
export const auth = firebase.auth()
export default db;