import React, { Component } from 'react';
import Slider from 'react-slick';
import db, { storage } from './firebase/firebase';
import Loader from 'react-loader-spinner'
import { Link } from 'react-router-dom';
import background from '../assets/bg.png';
import logo from '../assets/logo1.png';
import plate from '../assets/plate.png';
import juice from '../assets/juice.png';
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from 'emailjs-com';
import showToast from './util/toast'
import home from '../assets/home2.png';

class Contact extends Component {

    state = {
        name: '',
        email: '',
        phone: '',
        msg: '',
        loader: false,
    }

    sendEmail = (e) => {
        if (this.state.name === ''
            || this.state.email === ''
            || this.state.phone === ''
            || this.state.msg === '') {
            showToast('error', 'Fill the form')
            return
        }

        this.setState({ loader: true })
        e.preventDefault();    // This is important, i'm not sure why, but the email won't send without it
        emailjs.sendForm('gmail', 'template_mdhcc9i', e.target, '5yIURgbf9PsA9yLIR')
            .then((result) => {
                this.setState({ loader: false })
                showToast('success', 'Email Sent!')
                setTimeout(() => {
                    window.location.reload()
                }, 1500);
            });
    }

    render() {
        return (
            <div style={{ marginTop: 0, marginLeft: 0, marginRight: 0, padding: 0 }}>
                <img
                    src={background}
                    style={{ position: 'absolute', width: '100%', height: '100vh', objectFit: 'cover' }} />
                <div
                    style={{ position: 'absolute', width: '100%', display: 'flex', flexDirection: 'column', padding: 0 }}>
                    <div style={{ marginTop: 30, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ display: 'flex' }}>
                            <img
                                src={plate}
                                className='plate-juice' />
                            <img
                                src={logo}
                                className='logo-title' />
                            <img
                                src={juice}
                                className='plate-juice' />
                        </div>

                        {this.state.loader ?
                            <div style={{ position:'absolute', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
                                <div style={{ padding: 10, backgroundColor: 'red', borderRadius: 100 }}>
                                    <Loader
                                        type="ThreeDots"
                                        color="white"
                                        height={70}
                                        width={70}
                                        visible={true} />
                                </div>
                            </div>
                            :
                            null
                        }
                        
                        <Form onSubmit={this.sendEmail} className='form' style={{ marginTop: 10, padding: 20, backgroundColor: 'rgba(0,0,0,0.2)', borderRadius: 10 }}>
                            <h5 style={{ color: 'white' }}>
                                Send us a message we will respond asap!
                            </h5>
                            <FormGroup style={{ marginTop: 15 }}>
                                <Input
                                    name="from_name"
                                    style={{ fontSize: 18 }}
                                    type='text'
                                    placeholder='Name'
                                    value={this.state.name}
                                    onChange={(event) => {
                                        console.log('change: ' + event.target.value);
                                        this.setState({ name: event.target.value })
                                    }} />
                            </FormGroup>
                            <FormGroup style={{ marginTop: 15 }}>
                                <Input
                                    name="from_email"
                                    style={{ fontSize: 18 }}
                                    type='email'
                                    placeholder='Email'
                                    value={this.state.email}
                                    onChange={(event) => {
                                        console.log('change: ' + event.target.value);
                                        this.setState({ email: event.target.value })
                                    }} />
                            </FormGroup>
                            <FormGroup style={{ marginTop: 15 }}>
                                <Input
                                    name="from_phone"
                                    style={{ fontSize: 18 }}
                                    type='number'
                                    placeholder='Phone'
                                    value={this.state.phone}
                                    onChange={(event) => {
                                        console.log('change: ' + event.target.value);
                                        this.setState({ phone: event.target.value })
                                    }} />
                            </FormGroup>
                            <FormGroup style={{ marginTop: 15 }}>
                                <Input
                                    name="message"
                                    style={{ fontSize: 18, minHeight: 200 }}
                                    type='textarea'
                                    placeholder='Your message....'
                                    value={this.state.msg}
                                    onChange={(event) => {
                                        console.log('change: ' + event.target.value);
                                        this.setState({ msg: event.target.value })
                                    }} />
                            </FormGroup>
                            <FormGroup style={{ marginTop: 15 }}>
                                <input
                                    type="submit"
                                    className='btn-block btn-dark'
                                    style={{ marginTop: 0, height: 40, fontSize: 18, fontWeight: 'bold', width: '100%', backgroundColor: '#066db4', borderWidth: 0, borderRadius: 5 }}
                                    value="Send" />
                            </FormGroup>
                        </Form>
                    </div>
                </div>
                <div>
                    <ToastContainer />
                </div>

                <button 
                    onClick={()=>{
                        window.location.replace('/')
                    }}
                    className='home-btn'>
                    <img
                        src={home}
                        className='home-btn-img' />
                </button>

            </div>
        )
    }
}

export default Contact;