import React, { useState, useEffect } from 'react';
import '../../App.css';
import { Link, useHistory } from 'react-router-dom';
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import MyStorage from '.././util/storage'
import background from '../../assets/bg.png';
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';
import home from '../../assets/home2.png';
import Api from '../api/api'
import showToast from '.././util/toast'
import Loader from 'react-loader-spinner'
import { ToastContainer } from 'react-toastify';

var loaded = false

const AdminSettings = () => {
  const history = useHistory()
  const [price, setPrice] = useState('0')
  const [address, setAddress] = useState('')
  const [lat, setLat] = useState('')
  const [lng, setLng] = useState('')
  const [date, setDate] = useState(new Date())
  const [dateList, setDateList] = useState([])

  const [models, setModels] = useState([{
    id: Math.random(1000) + 1,
    price: '0',
    limit: '50',
    address: '',
    lat: '',
    lng: '',
    date: new Date(),
  }])
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    if (!loaded)
      loadSettings()
  }, [dateList])

  const loadSettings = () => {
    Api.getSettings(
      (settings) => {
        console.log('settings: ' + JSON.stringify(settings.settings));
        let models = []
        for (let i = 0; i < settings.settings.length; i++) {
          const model = settings.settings[i];
          let temp = {
            id: model.id,
            price: model.price,
            limit: model.limit ? model.limit + '' : '50',
            address: model.city.address,
            lat: model.city.lat,
            lng: model.city.lng,
            date: new Date(moment(model.dateTime, 'MM-DD-YYYY h:m a')),
          }
          models.push(temp)
        }
        setModels(models)
        loaded = true
      },
      (error) => {
        //
      });
  };

  const makeDateListArrayLocal = (arr) => {
    let list = []
    for (let i = 0; i < arr.length; i++) {
      const item = arr[i];
      list = list.concat({ id: item.id, date: new Date(moment(item.date, 'MM-DD-YYYY h:m a')) })
    }
    return list
  };

  const makeDateListArrayServer = () => {
    let list = []
    for (let i = 0; i < dateList.length; i++) {
      const item = dateList[i];
      list = list.concat({ id: item.id, date: moment(item.date).format('MM-DD-YYYY h:m a') })
    }
    return list
  };

  const saveSettings = () => {
    let settings = []
    for (let i = 0; i < models.length; i++) {
      const model = models[i];
      if (model.price === '' || model.limit === '' || model.address === '' || model.lat === '' || model.lng === '') {
        showToast('error', 'Fill form')
        return
      }
      let obj = {
        id: model.id,
        price: model.price,
        limit: model.limit,
        city: {
          address: model.address,
          lat: model.lat,
          lng: model.lng
        },
        dateTime: moment(model.date).format('MM-DD-YYYY h:m a')
      }
      settings.push(obj)
    }

    setLoader(true)
    Api.saveSettings({ settings: settings },
      () => {
        setLoader(false)
        showToast('success', 'Settings Saved!')
      },
      (error) => {
        setLoader(false)
      });

    // let settings = {
    //   price: price,
    //   city: {
    //     address: address,
    //     lat: lat,
    //     lng: lng
    //   },
    //   dateTime: makeDateListArrayServer()
    // }
    // setLoader(true)
    // Api.saveSettings(settings,
    //   () => {
    //     setLoader(false)
    //     showToast('success', 'Settings Saved!')
    //   },
    //   (error) => {
    //     setLoader(false)
    //   });
  };

  if (!MyStorage.isLogin()) {
    console.log('not logged in');
    history.replace('/login')
    return null
  }

  const updateModel = (item, field, value) => {
    const list = models.map((item_) => {
      if (item.id === item_.id) {
        return { ...item_, [field]: value };
      }
      else {
        return item_;
      }
    });
    console.log('list: ' + JSON.stringify(list));
    setModels(list)
  };

  return (
    <div style={{ marginTop: 0, marginLeft: 0, marginRight: 0, padding: 0 }}>
      <img
        src={background}
        style={{ position: 'absolute', width: '100%', height: '100vh', objectFit: 'cover' }} />
      <div
        style={{ position: 'absolute', width: '100%', display: 'flex', flexDirection: 'column', padding: 0 }}>
        <div style={{ marginTop: 30, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          <h3 style={{ color: 'white', alignSelf: 'center' }}>Settings</h3>

          {loader ?
            <div style={{ position: 'absolute', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
              <div style={{ padding: 10, backgroundColor: 'red', borderRadius: 100 }}>
                <Loader
                  type="ThreeDots"
                  color="white"
                  height={70}
                  width={70}
                  visible={true} />
              </div>
            </div>
            :
            null
          }

          <div style={{ padding: 10, backgroundColor: 'white', borderRadius: 10, width: 500, display: 'flex', flexDirection: 'column', overflow: 'scroll', paddingBottom: 100 }}>

            {models.map((item, index) => {
              return (
                <div style={{ backgroundColor: '#f2f2f2', padding: 10, marginTop: 10 }}>
                  <div style={{ display: 'flex' }}>
                    <div style={{ color: 'blue' }}>
                      Brunch {index + 1}
                    </div>
                    <div style={{ display: 'flex', flex: 1, alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                      <button
                        style={{ backgroundColor: 'red', color: 'white', height: 35, width: 50, }}
                        onClick={() => {
                          const temp = models.filter((item_, j) => {
                            return item.id !== item_.id; //false will delete
                          });
                          setModels(temp)
                        }}>
                        -
                      </button>
                    </div>
                  </div>
                  <FormGroup style={{ marginTop: 5 }}>
                    <div style={{ marginBottom: 5 }}>Per Seat Price</div>
                    <Input
                      style={{}}
                      type='number'
                      placeholder=''
                      value={item.price}
                      onChange={(event) => {
                        updateModel(item, 'price', event.target.value)
                      }} />
                  </FormGroup>
                  <FormGroup style={{ marginTop: 5 }}>
                    <div style={{ marginBottom: 5 }}>People Limit</div>
                    <Input
                      style={{}}
                      type='number'
                      placeholder=''
                      value={item.limit}
                      onChange={(event) => {
                        updateModel(item, 'limit', event.target.value)
                      }} />
                  </FormGroup>
                  <FormGroup style={{ marginTop: 5 }}>
                    <div style={{ marginBottom: 5 }}>Brunch Location</div>
                    <Input
                      style={{}}
                      type='text'
                      placeholder='Enter address...'
                      value={item.address}
                      onChange={(event) => {
                        updateModel(item, 'address', event.target.value)
                      }} />
                  </FormGroup>
                  <FormGroup style={{ marginTop: 5, display: 'flex' }}>
                    <Input
                      style={{ marginRight: 5, }}
                      type='number'
                      placeholder='Latitude'
                      value={item.lat}
                      onChange={(event) => {
                        updateModel(item, 'lat', event.target.value)
                      }} />
                    <Input
                      style={{}}
                      type='number'
                      placeholder='Longitude'
                      value={item.lng}
                      onChange={(event) => {
                        updateModel(item, 'lng', event.target.value)
                      }} />
                  </FormGroup>
                  <div style={{ display: 'flex', marginTop: 10 }}>
                    Brunch Date & Time
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ width: 212, alignSelf: 'center', marginTop: 0, backgroundColor: 'white', borderRadius: 5, height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <DateTimePicker
                        key={index.toString()}
                        format='MM/dd/yyyy h:m a'
                        onChange={(date) => {
                          updateModel(item, 'date', date)
                        }}
                        value={item.date} />
                    </div>
                  </div>

                </div>
              )
            })}

            <button
              style={{ marginTop: 10, backgroundColor: 'green', color: 'white', width: 50, alignSelf: 'center' }}
              onClick={() => {
                let temp = [...models]
                temp = temp.concat({
                  id: Math.random(1000) + 1,
                  price: '0',
                  limit: '50',
                  address: '',
                  lat: '',
                  lng: '',
                  date: new Date(),
                });
                console.log('temp: ' + JSON.stringify(temp));
                setModels(temp)
              }}>
              +
            </button>
            <Button
              className='btn-block btn-dark'
              style={{ marginTop: 20, fontSize: 18, fontWeight: 'bold', width: '100%', backgroundColor: 'orange', borderWidth: 0, height: 50 }}
              onClick={() => {
                saveSettings()
              }}>
              Save Settings
            </Button>
          </div>
        </div>
      </div>
      <div>
        <ToastContainer />
      </div>
      <button
        onClick={() => {
          window.location.replace('/admin-dashboard')
        }}
        className='home-btn'>
        <img
          src={home}
          className='home-btn-img' />
      </button>
    </div>
  );
}

export default AdminSettings;