import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner'
import background from '../assets/bg.png';
import logo from '../assets/logo_gallery.png';
import plate from '../assets/plate.png';
import juice from '../assets/juice.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Api from './api/api'
import home from '../assets/home2.png';
import Parser from 'html-react-parser';
import db, { storage } from './firebase/firebase';
import MyStorage from './util/storage'

const GalleryCollections = () => {
    const history = useHistory()
    const [models, setModels] = useState([])
    const [events, setEvents] = useState([])
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        loadData()
    }, [])

    // if (!MyStorage.isLogin()) {
    //     console.log('not logged in');
    //     history.replace('/login')
    //     return null
    // }

    const loadData = () => {
        setLoader(true)
        db.collection("gallery")
            .get()
            .then(querySnapshot => {
                setLoader(false)
                let temp = []
                let temp1 = []
                for (let i = 0; i < querySnapshot.docs.length; i++) {
                    const doc = querySnapshot.docs[i];
                    let item = doc.data()
                    temp.push(item)
                    if (item.eventTitle) {
                        temp1.push(item)
                    }
                }
                // group items
                let groups = groupItems(temp1)
                console.log('GROUPS: ' + JSON.stringify(groups));
                console.log('groups: ' + groups.length);
                // groups.push(groups[0])
                // groups.push(groups[0])
                // groups.push(groups[0])
                // groups.push(groups[0])
                // groups.push(groups[0])
                // groups.push(groups[0])
                // groups.push(groups[0])
                // groups.push(groups[0])
                // groups.push(groups[0])
                // groups.push(groups[0])
                setEvents(groups)
                setModels(temp)
            })
            .catch(e => {
                setLoader(false)
            });
    };

    const groupItems = (items) => {
        const memo = items.reduce((memo, object) => {
            if (object.eventTitle in memo)
                memo[object.eventTitle].push(object);
            else
                memo[object.eventTitle] = [object];
            return memo;
        }, {});
        let models = [];
        Object.keys(memo)
            .reduce((a, key) => {
                const objects = memo[key]
                    .sort()
                // console.log(key);
                // console.log(JSON.stringify(objects));
                let model = {
                    key: key,
                    data: objects
                }
                models = models.concat(model);
            }, [])
        return models;
    }

    return (
        <div style={{ marginTop: 0, marginLeft: 0, marginRight: 0, padding: 0 }}>
            <img
                src={background}
                style={{ position: 'absolute', width: '100%', height: '100vh', objectFit: 'cover' }} />
            <div
                style={{ position: 'absolute', width: '100%', display: 'flex', flexDirection: 'column', padding: 0 }}>
                <div style={{ display: 'flex', alignSelf: 'center', marginTop: 30, marginBottom: 0 }}>
                    <img
                        src={plate}
                        className='plate-juice' />
                    <img
                        src={logo}
                        className='logo-title' />
                    <img
                        src={juice}
                        className='plate-juice' />
                </div>

                {/* {!this.state.loader && this.state.models.length == 0 ?
                        <div style={{ alignSelf: 'center', padding: 20, backgroundColor: '#f2f2f2', marginTop: 50, borderRadius: 10, alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                            <div style={{ fontSize: 18 }}>
                                No Upcoming Events
                            </div>
                        </div>
                        :
                        null
                    } */}

                {loader ?
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
                        <div style={{ padding: 10, backgroundColor: 'red', borderRadius: 100 }}>
                            <Loader
                                type="ThreeDots"
                                color="white"
                                height={70}
                                width={70}
                                visible={true} />
                        </div>
                    </div>
                    :
                    null
                }

                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', height: '85vh', overflow: 'scroll', paddingBottom: 140, backgroundColor: 'transparent', borderRadius: 20 }}>
                    {events.map((event, index) => {
                        return (
                            <div
                                onClick={() => {
                                    history.push({
                                        pathname: '/gallery',
                                        state: { event: event }
                                    })
                                }}
                                style={{ width: 210, height: 270, backgroundColor: '#f2f2f2', marginTop: 10, padding: 0, marginRight: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', borderRadius: 10, overflow: 'hidden' }}>
                                <img src={event.data[0].image} style={{ width: 200, height: 200, objectFit: 'cover' }} />
                                <div style={{ maxLines: 1, width: '100%', paddingTop: 8, color: 'red', backgroundColor: '#f2f2f2', textAlign: 'center', fontSize: 16, fontWeight: 'bold', textTransform: 'capitalize' }}>{event.key.length < 35 ? event.key : event.key.substring(0, 35) + '...'}</div>
                                <div style={{ width: '100%', paddingBottom: 8, backgroundColor: '#f2f2f2', textAlign: 'center', fontSize: 14 }}>{event.data.length} Photo(s)</div>
                            </div>
                        )
                    })}
                </div>

            </div>
            <div>
                <ToastContainer />
            </div>

            <button
                onClick={() => {
                    window.location.replace('/')
                }}
                className='home-btn'>
                <img
                    src={home}
                    className='home-btn-img' />
            </button>

        </div>
    )
}

export default GalleryCollections;