import React, { useState, useEffect } from 'react';
import '../App.css';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import MyStorage from './util/storage'
import background from '../assets/bg.png';
import home from '../assets/home2.png';
import Loader from 'react-loader-spinner'
import { ToastContainer } from 'react-toastify';
import Api from './api/api'
import cross from '../assets/cross.png';

const VendorDetails = () => {
    const history = useHistory()
    const location = useLocation()

    const item = location.state.item
    console.log('ITEM: ' + JSON.stringify(item));

    const [showFullImage, setShowFullImage] = useState(false)
    const [fullImageUrl, setFullImageUrl] = useState(false)

    useEffect(() => {
        //
    }, [])

    return (
        <div style={{ marginTop: 0, marginLeft: 0, marginRight: 0, padding: 0 }}>
            <img
                src={background}
                style={{ position: 'absolute', width: '100%', height: '100vh', objectFit: 'cover' }} />
            <div
                style={{ position: 'absolute', width: '100%', display: 'flex', flexDirection: 'column', padding: 0 }}>
                <div style={{ padding: 30, display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'scroll' }}>
                    <h3 style={{ color: 'white', alignSelf: 'center' }}>Vendor Details</h3>
                    <div style={{ marginTop: 20, backgroundColor: 'white', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>

                        <div style={{ display: 'flex', backgroundColor: '#f2f2f2', padding: 10 }}>
                            <img
                                src={item.logo}
                                style={{ width: 120, height: 120, borderRadius: 60, border: '2px solid lightgray', objectFit: 'cover', marginRight: 15, alignSelf: 'center' }} />

                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex' }}>
                                    {/* <div style={{ fontSize: 16, display: 'flex', color: 'gray' }}>Business Name:</div> */}
                                    <div style={{ fontSize: 16, display: 'flex', marginLeft: 0 }}>{item.bname}</div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    {/* <div style={{ fontSize: 16, display: 'flex', color: 'gray' }}>Contact Name:</div> */}
                                    <div style={{ fontSize: 16, display: 'flex', marginLeft: 0 }}>{item.cname}</div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    {/* <div style={{ fontSize: 16, display: 'flex', color: 'gray' }}>Phone:</div> */}
                                    <div style={{ fontSize: 16, display: 'flex', marginLeft: 0, color: 'blue' }}>{item.phone}</div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    {/* <div style={{ fontSize: 16, display: 'flex', color: 'gray' }}>Email:</div> */}
                                    <button
                                        onClick={() => {
                                            window.open('mailto:' + item.email)
                                        }}
                                        style={{ border: '0px', padding: 0, fontSize: 16, display: 'flex', marginLeft: 0, color: 'blue' }}>
                                        {item.email}
                                    </button>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    {/* <div style={{ fontSize: 16, display: 'flex', color: 'gray' }}>Website:</div> */}
                                    <button
                                        onClick={() => {
                                            window.location = item.website.includes('http') ? item.website : 'https://' + item.website
                                        }}
                                        style={{ border: '0px', padding: 0, fontSize: 16, display: 'flex', marginLeft: 0, color: 'blue' }}>
                                        {item.website}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', paddingLeft: 20, marginBottom: 10 }}>
                            {item.images.map((image, ind) => {
                                return (
                                    <button style={{ border: '0px', padding: 0, backgroundColor: 'transparent' }}>
                                        <img
                                            onClick={() => {
                                                setFullImageUrl(image)
                                                setShowFullImage(true)
                                            }}
                                            src={image}
                                            style={{ width: 100, height: 100, border: '1px solid lightgray', objectFit: 'contain', marginTop: 10, marginRight: 20, alignSelf: 'center' }} />
                                    </button>
                                )
                            })}
                        </div>


                        <div style={{ display: 'flex', padding:20 }}>
                            <div style={{ fontSize: 16, display: 'flex' }}>{item.detail}</div>
                        </div>

                    </div>
                </div>
            </div>

            <button
                onClick={() => {
                    window.location.replace('/')
                }}
                className='home-btn'>
                <img
                    src={home}
                    className='home-btn-img' />
            </button>

            {showFullImage ?
                <div style={{ position: 'absolute', flex: 1, display: 'flex', backgroundColor: 'white', width: '100%', height: '100%' }}>
                    <img
                        src={fullImageUrl}
                        style={{ width: '100%', height: '100%', border: '1px solid lightgray', objectFit: 'contain', alignSelf: 'center' }} />
                    <img
                        onClick={() => {
                            setFullImageUrl('')
                            setShowFullImage(false)
                        }}
                        src={cross}
                        style={{ position: 'absolute', top: 30, right: 30, width: 40, height: 40 }} />
                </div>
                :
                null
            }

        </div>
    );
}

export default VendorDetails;