import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Loader from 'react-loader-spinner'
import background from '../assets/bg.png';
import logo from '../assets/logo_gallery.png';
import plate from '../assets/plate.png';
import juice from '../assets/juice.png';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import home from '../assets/home2.png';
import ImageGallery from 'react-image-gallery';
import MyStorage from './util/storage'

const Gallery = () => {
    const history = useHistory()

    const location = useLocation()
    let event = location.state.event
    // console.log(JSON.stringify(event));

    let models = event.data
    console.log(JSON.stringify(models));
    for (let i = 0; i < models.length; i++) {
        models[i].original = models[i].image
    }
    const [loader, setLoader] = useState(false)

    // if (!MyStorage.isLogin()) {
    //     console.log('not logged in');
    //     history.replace('/login')
    //     return null
    // }

    return (
        <div style={{ marginTop: 0, marginLeft: 0, marginRight: 0, padding: 0 }}>
            <img
                src={background}
                style={{ position: 'absolute', width: '100%', height: '100vh', objectFit: 'cover' }} />
            <div
                style={{ position: 'absolute', width: '100%', display: 'flex', flexDirection: 'column', padding: 0 }}>
                <div style={{ display: 'flex', alignSelf: 'center', marginTop: 20, marginBottom: 0 }}>
                    <img
                        src={plate}
                        className='plate-juice' />
                    <img
                        src={logo}
                        className='logo-title' />
                    <img
                        src={juice}
                        className='plate-juice' />
                </div>

                {!loader && models.length == 0 ?
                    <div style={{ alignSelf: 'center', padding: 20, backgroundColor: '#f2f2f2', marginTop: 50, borderRadius: 10, alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                        <div style={{ fontSize: 18 }}>
                            No Photos
                        </div>
                    </div>
                    :
                    null
                }

                {loader ?
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
                        <div style={{ padding: 10, backgroundColor: 'red', borderRadius: 100 }}>
                            <Loader
                                type="ThreeDots"
                                color="white"
                                height={70}
                                width={70}
                                visible={true} />
                        </div>
                    </div>
                    :
                    null
                }

                <ImageGallery
                    items={models}
                    fullscreen={true}
                    autoPlay />

                {/* <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', height: '85vh', overflow: 'scroll', paddingBottom: 40, backgroundColor: 'transparent', borderRadius: 20 }}> */}
                {/* {this.state.models.map((item, index) => {
                            return (
                                <div style={{ width: 300, height: 450, backgroundColor: '#f2f2f2', marginRight: 20, marginTop: 20, borderRadius: 10, alignItems: 'center', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                                    <img
                                        src={item.image}
                                        style={{ width: '100%', height: 200, objectFit: 'cover' }} />
                                </div>
                            )
                        })} */}
                {/* </div> */}

            </div>
            <div>
                <ToastContainer />
            </div>

            <button
                onClick={() => {
                    window.location.replace('/')
                }}
                className='home-btn'>
                <img
                    src={home}
                    className='home-btn-img' />
            </button>

        </div>
    )
}

export default Gallery;