import React, { useState, useEffect } from 'react';
import '../App.css';
import { Link, useHistory } from 'react-router-dom';
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import MyStorage from './util/storage'
import background from '../assets/bg.png';
import home from '../assets/home2.png';
import Loader from 'react-loader-spinner'
import { ToastContainer } from 'react-toastify';
import Api from './api/api'
import cross from '../assets/cross.png';

const Vendors = () => {
    const history = useHistory()
    const [models, setModels] = useState([])
    const [loader, setLoader] = useState(false)
    const [showFullImage, setShowFullImage] = useState(false)
    const [fullImageUrl, setFullImageUrl] = useState(false)

    useEffect(() => {
        console.log('useEffect: '+models.length);
        if (models.length == 0)
            loadData()
    }, [])

    const loadData = () => {
        setLoader(true)
        Api.getVendors(
            (response) => {
                setLoader(false)
                setModels(response)
            },
            (error) => {
                setLoader(false)
            });
    };

    return (
        <div style={{ marginTop: 0, marginLeft: 0, marginRight: 0, padding: 0 }}>
            <img
                src={background}
                style={{ position: 'absolute', width: '100%', height: '100vh', objectFit: 'cover' }} />
            <div
                style={{ position: 'absolute', width: '100%', display: 'flex', flexDirection: 'column', padding: 0 }}>
                <div style={{ padding: 30, display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'scroll' }}>
                    <h3 style={{ color: 'white', alignSelf: 'center' }}>Vendors</h3>
                    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                        {models.map((item, index) => {
                            return (
                                <div style={{ width: '100%', backgroundColor: 'white', marginTop: 10, padding: 0, width: 400, marginRight: 10, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>

                                    <div style={{ display: 'flex', backgroundColor: '#f2f2f2', padding: 10 }}>
                                        <img
                                            src={item.logo}
                                            style={{ width: 120, height: 120, borderRadius: 60, border: '2px solid lightgray', objectFit: 'cover', marginRight: 15, alignSelf: 'center' }} />

                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div style={{ display: 'flex' }}>
                                                {/* <div style={{ fontSize: 16, display: 'flex', color: 'gray' }}>Business Name:</div> */}
                                                <div style={{ fontSize: 16, display: 'flex', marginLeft: 0 }}>{item.bname}</div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                {/* <div style={{ fontSize: 16, display: 'flex', color: 'gray' }}>Contact Name:</div> */}
                                                <div style={{ fontSize: 16, display: 'flex', marginLeft: 0 }}>{item.cname}</div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                {/* <div style={{ fontSize: 16, display: 'flex', color: 'gray' }}>Phone:</div> */}
                                                <div style={{ fontSize: 16, display: 'flex', marginLeft: 0, color: 'blue' }}>{item.phone}</div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                {/* <div style={{ fontSize: 16, display: 'flex', color: 'gray' }}>Email:</div> */}
                                                <button
                                                    onClick={() => {
                                                        window.open('mailto:' + item.email)
                                                    }}
                                                    style={{ border: '0px', padding: 0, fontSize: 16, display: 'flex', marginLeft: 0, color: 'blue' }}>
                                                    {item.email}
                                                </button>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                {/* <div style={{ fontSize: 16, display: 'flex', color: 'gray' }}>Website:</div> */}
                                                <button
                                                    onClick={() => {
                                                        window.location = item.website.includes('http') ? item.website : 'https://' + item.website
                                                    }}
                                                    style={{ border: '0px', padding: 0, fontSize: 16, display: 'flex', marginLeft: 0, color: 'blue' }}>
                                                    {item.website}
                                                </button>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                {/* <div style={{ fontSize: 16, display: 'flex' }}>{item.detail}</div> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', paddingLeft: 20, marginBottom: 10 }}>
                                        {item.images.map((image, ind) => {
                                            if (ind > 2) return null
                                            return (
                                                <button style={{ border: '0px', padding: 0, backgroundColor: 'transparent' }}>
                                                    <img
                                                        onClick={() => {
                                                            setFullImageUrl(image)
                                                            setShowFullImage(true)
                                                        }}
                                                        src={image}
                                                        style={{ width: 100, height: 100, border: '1px solid lightgray', objectFit: 'contain', marginTop: 10, marginRight: 20, alignSelf: 'center' }} />
                                                </button>
                                            )
                                        })}
                                    </div>

                                    <button
                                        onClick={() => {
                                            history.push({
                                                pathname: '/vendor-details',
                                                state: { item: item }
                                            })
                                        }}
                                        type="button"
                                        className='btn-block btn-dark'
                                        style={{ height: 50, fontSize: 16, fontWeight: 'bold', backgroundColor: 'orange', borderWidth: 0, borderRadius: 5, marginLeft: 10, marginRight: 10, marginBottom: 10 }}>
                                        View Details
                                    </button>

                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            {loader ?
                <div style={{ position: 'absolute', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <div style={{ padding: 10, backgroundColor: 'red', borderRadius: 100 }}>
                        <Loader
                            type="ThreeDots"
                            color="white"
                            height={70}
                            width={70}
                            visible={true} />
                    </div>
                </div>
                :
                null
            }

            <button
                onClick={() => {
                    window.location.replace('/')
                }}
                className='home-btn'>
                <img
                    src={home}
                    className='home-btn-img' />
            </button>

            {models.length > 0 && showFullImage ?
                <div style={{ position: 'absolute', flex: 1, display: 'flex', backgroundColor: 'white', width: '100%', height: '100%' }}>
                    <img
                        src={fullImageUrl}
                        style={{ width: '100%', height: '100%', border: '1px solid lightgray', objectFit: 'contain', alignSelf: 'center' }} />
                    <img
                        onClick={() => {
                            setFullImageUrl('')
                            setShowFullImage(false)
                        }}
                        src={cross}
                        style={{ position: 'absolute', top: 30, right: 30, width: 40, height: 40 }} />
                </div>
                :
                null
            }

        </div>
    );
}

export default Vendors;